import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForward from '@material-ui/icons/ArrowForward';
import useStylesContent from '../../../useStylesContent';

const Summarizer: React.FC = () => {
    const classes = useStylesContent();

    return (
        <>
            <Typography variant="h2" component="h2" gutterBottom>
                Ваш стиль «Испытатель»
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Специализируется на&nbsp;претворении планов в&nbsp;жизнь
                и&nbsp;достижениях результатов
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
                Воспринимает информацию путем Абстрактной концептуализации
                и&nbsp;обрабатывает ее&nbsp;через Активное экспериментирование.
                Если вы&nbsp;предпочитаете такой стиль обучения, вы&nbsp;умеете
                решать задачи и&nbsp;принимать решения, основываясь
                на&nbsp;поисках ответов на&nbsp;вопросы или решений проблем.
                Вы&nbsp;умеете находить практическое применение идеям
                и&nbsp;теориям&nbsp;и, скорее всего, будете выполнять
                технические задачи лучше, чем решать вопросы социального
                и&nbsp;межличностного характера. Обучающихся испытателей главным
                образом интересует, как все работает. Лидер/руководитель должен
                дать им&nbsp;возможность для исследования.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Испытатели:
            </Typography>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Сильные стороны
                        </Typography>
                        <Typography variant="body1">
                            Решение задач, принятие решений, дедуктивное
                            обоснование, определение проблем, практическое
                            применение идей
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Стиль работы
                        </Typography>
                        <Typography variant="body1">
                            Фактические данные, собранные через личное участие
                            и&nbsp;практический опыт
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">Мотивация</Typography>
                        <Typography variant="body1">
                            Приводить их&nbsp;собственное видение настоящего
                            в&nbsp;соответствие с&nbsp;тем, каким они видят
                            безопасное будущее
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Любимый вопрос
                        </Typography>
                        <Typography variant="body1">Как?</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="body1" className={classes.paragraph}>
                Такие навыки обучения важны для достижения высоких результатов в
                узкоспециализированных и технологичных сферах.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Люди с&nbsp;данным стилем обучения:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        интегрируют теорию и&nbsp;практику;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        стремятся к&nbsp;созданию целостных картин;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        учатся, проверяя теории и&nbsp;применяя здравый смысл;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        прагматичны и&nbsp;считают, что если что-то работает,
                        то&nbsp;это и&nbsp;нужно использовать;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        рационально подходят к&nbsp;решению проблем,
                        не&nbsp;любят получать готовые ответы;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        сразу переходят к&nbsp;делу, не&nbsp;любят долгие
                        церемонии;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        ценят стратегическое мышление, конкретные идеи;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        должны знать, как все устроено и&nbsp;работает;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        меняют реальность, переходя сразу к&nbsp;самой сути;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        стремятся к&nbsp;извлечению пользы и&nbsp;получению
                        результатов;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        могут иногда казаться властными и&nbsp;равнодушными.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Когда они руководят другими:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        успешны в&nbsp;составлении планов и&nbsp;определении
                        сроков;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        руководя, демонстрируют собственную напористость,
                        обращают внимание других на&nbsp;качество работы;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        с&nbsp;подчиненными демонстрируют подход кнута
                        и&nbsp;пряника (чем меньше правил, тем лучше, однако
                        контролируют их&nbsp;исполнение);
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        работают на&nbsp;повышение продуктивности
                        и&nbsp;стабильности своей организации;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        нуждаются/хотят работать с&nbsp;сотрудниками, которые
                        ориентированы на&nbsp;выполнение задач и&nbsp;действуют
                        быстро.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Они также
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        решают задачи, принимая односторонние решения;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        заинтересованы в&nbsp;производительности
                        и&nbsp;компетенции;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        пытаются дать другим навыки, которые пригодятся
                        им&nbsp;в&nbsp;жизни;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        считают знания инструментом, который позволяет людям
                        самостоятельно находить свой путь;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        поощряют практическое применение;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        любят технические навыки и&nbsp;практическую
                        деятельность;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        считают, что лучший способ определяется научным путем;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        используют измеряемые поощрения;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        иногда могут быть негибкими и&nbsp;замкнутыми;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        могут испытывать недостаток навыков командной работы.
                    </Typography>
                </li>
            </ul>
        </>
    );
};

export default Summarizer;
