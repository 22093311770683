import { Svg } from '@svgdotjs/svg.js';
import PDF_STYLES from './styles';
import PDF_CONTENT from './pdfContent';

import ikeaLogo from '../../../assets/img/logo.png';
import smartheadLogo from '../../../assets/img/logo_SmartHead.png';

interface IPdfOptions {
    result: number;
    userName?: string;
    chartImage: Svg;
}

const { REACT_APP_VERSION } = process.env;
let logo = ikeaLogo;
let logoWidth = 50;
if (REACT_APP_VERSION === 'smarthead') {
    logo = smartheadLogo;
    logoWidth = 60;
}

const getPDF: (options: IPdfOptions) => void = ({
    result,
    userName,
    chartImage,
}) => {
    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [70, 30, 70, 50],
        styles: PDF_STYLES,

        content: [
            {
                style: 'h3',
                margin: [0, 0, 0, 5],
                text: userName || ' ',
            },
            {
                style: 'body1',
                margin: [0, 0, 0, 25],
                text: `${dd}.${mm}.${yyyy}`,
            },
            {
                width: logoWidth,
                margin: [0, 0, 0, -21],
                alignment: 'right',
                image: logo,
            },
            {
                style: 'overline',
                margin: [0, 0, 0, 5],
                text: 'РЕЗУЛЬТАТ ТЕСТА ДЭВИДА КОЛБА',
            },
            {
                style: 'h3',
                text: 'Выявление предпочтений в обучении',
            },
            {
                style: 'h1',
                margin: 0,
                text: 'Профиль предпочтений в обучении',
            },
            {
                width: 350,
                alignment: 'center',
                margin: [0, 40, 0, 60],
                svg: new XMLSerializer().serializeToString(chartImage.node),
            },
            ...PDF_CONTENT[result],
        ],
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.pdfMake
        .createPdf(docDefinition)
        .download(
            `${userName ? `${userName}, ` : ''}Результат теста Дэвида Колба`
        );
};

export default getPDF;
