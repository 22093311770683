const TESTER = [
    {
        style: 'h2',
        text: 'Ваш стиль «Экспериментатор»',
    },
    {
        style: 'h3',
        text: 'Специализируется на принятии и адаптации изменений',
    },
    {
        style: 'body1',
        text:
            'Воспринимает информацию через Конкретный Опыт и обрабатывает ее через Активное Экспериментирование.',
    },
    {
        style: 'body1',
        text:
            'Если вы предпочитаете такой стиль, вы, вероятно, любите претворять планы в жизнь и решать новые сложные задачи, руководствуетесь больше интуицией, а не результатами логического анализа. В решении задач вы скорее всего больше ориентируетесь на то, как действуют другие люди в определенных ситуациях, нежели на результаты собственного анализа.',
    },
    {
        style: 'body1',
        text:
            'Обучающиеся экспериментаторы главным образом заинтересованы в самопознании. Лидеры/руководители должны позволять/помогать им самостоятельно получать знания и передавать их другим.',
    },
    {
        style: 'list',
        margin: [12, 13, 0, 9],
        ul: [
            [
                { style: 'subtitle', text: 'Сильные стороны' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Работа на результат, лидерство, принятие рисков.',
                },
            ],
            [
                { style: 'subtitle', text: 'Стиль работы' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Действие и экспериментирование.',
                },
            ],
            [
                { style: 'subtitle', text: 'Мотивация' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Пробовать новые идеи на практике.',
                },
            ],
            [
                { style: 'subtitle', text: 'Любимый вопрос' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'А что, если...?',
                },
            ],
        ],
    },
    {
        style: 'body1',
        text:
            'Такой стиль обучения важен для достижения успеха в профессиях, ориентированных на действия, например, в области маркетинга и продаж.',
    },
    {
        style: 'h4',
        text: 'Люди с данным стилем обучения:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Интегрируют опыт и практическое применение;',
            },
            {
                style: 'body1',
                text: 'Учатся методом проб и ошибок;',
            },
            { style: 'body1', text: 'Верят в самопознание;' },
            {
                style: 'body1',
                text:
                    'С энтузиазмом относятся к новому, легко подстраиваются под изменения и даже получают удовольствие от них;',
            },
            {
                style: 'body1',
                text: 'Преуспевают там, где необходима гибкость;',
            },
            {
                style: 'body1',
                text:
                    'Часто приходят к точным выводам при отсутствии логического обоснования;',
            },
            { style: 'body1', text: 'Готовы идти на риск;' },
            { style: 'body1', text: 'Легко общаются с людьми;' },
            {
                style: 'body1',
                text:
                    'Обогащают реальность, дополняя уже известное результатами своих экспериментов;',
            },
            {
                style: 'body1',
                text:
                    'Ищут пути влияния на других, поэтому иногда производят впечатление манипуляторов и слишком напористых людей.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Когда они руководят другими:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: '«Расцветают» в ситуациях кризиса и трудных задач;',
            },
            {
                style: 'body1',
                text: 'В своем лидерстве заряжают людей энергией;',
            },
            {
                style: 'body1',
                text:
                    'С подчиненными демонстрируют видение того, каким может быть результат;',
            },
            {
                style: 'body1',
                text:
                    'Усердно работают, чтобы обеспечить репутацию лидера для своей организации;',
            },
            {
                style: 'body1',
                text:
                    'Нуждаются/хотят работать с сотрудниками, которые могут осуществлять последующий контроль и заниматься деталями.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Они также:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text:
                    'Решают задачи через поиск характерных проявлений проблем, изучая имеющиеся возможности;',
            },
            {
                style: 'body1',
                text:
                    'Заинтересованы в том, чтобы обеспечивать людям возможность самопознания;',
            },
            {
                style: 'body1',
                text:
                    'Помогают людям действовать в соответствии с их собственными представлениями;',
            },
            {
                style: 'body1',
                text:
                    'Считают знания необходимыми для повышения осведомленности более широкого круга людей;',
            },
            {
                style: 'body1',
                text: 'Поощряют обучение опытным путем;',
            },
            {
                style: 'body1',
                text: 'Любят разнообразие дидактических методов;',
            },
            {
                style: 'body1',
                text:
                    'Эмоциональные люди, которые любят заряжать других энергией;',
            },
            {
                style: 'body1',
                text: 'Пытаются создать новые формы, оживить все вокруг себя;',
            },
            {
                style: 'body1',
                text: 'Способны устанавливать новые границы;',
            },
            {
                style: 'body1',
                text:
                    'Иногда склонны к поспешным решениям и манипулированию другими.',
            },
        ],
    },
];

export default TESTER;
