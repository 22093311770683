import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import logo from '../../assets/img/logo.svg';
import logo_SmartHead from '../../assets/img/logo_SmartHead.svg';
import CustomTheme from '../../customTheme';

const useStyles = makeStyles({
    root: ({ internal }: IProps) => ({
        flex: '0 1 100%',
        display: 'flex',
        justifyContent: internal ? 'flex-end' : 'center',
        position: internal ? 'absolute' : 'static',
        top: '28px',
        right: '20px',
        left: '20px',
        margin: '0',
        padding: '0',

        [CustomTheme.breakpoints.up('sm')]: {
            justifyContent: internal ? 'flex-start' : 'center',
            position: 'static',
            marginBottom: internal ? '59px' : '16px',
        },

        [CustomTheme.breakpoints.up('md')]: {
            justifyContent: internal ? 'flex-start' : 'center',
            marginBottom: internal ? '60px' : '16px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginBottom: internal ? '60px' : '36px',
        },
    }),

    logo: {
        display: 'block',
        width: '88px',
        height: 'auto',
        userSelect: 'none',
        pointerEvents: 'none',

        [CustomTheme.breakpoints.up('sm')]: {
            width: '126px',
        },
    },

    logoTrigger: {
        cursor: 'pointer',
    },
});

interface IProps {
    internal?: boolean;
    handleSetStep?(direction: string): void;
}

const Header: React.FC<IProps> = ({ internal = false, handleSetStep }) => {
    const classes = useStyles({ internal });

    const goToStart = () => {
        if (handleSetStep) {
            handleSetStep('startPage');
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            goToStart();
        }
    };

    const handleClick = () => {
        goToStart();
    };

    return (
        <header className={classes.root}>
            <div
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
                className={classes.logoTrigger}
            >
                <object
                    aria-label="logo"
                    data={
                        process.env.REACT_APP_VERSION === 'ikea'
                            ? logo
                            : logo_SmartHead
                    }
                    type="image/svg+xml"
                    className={classes.logo}
                />
            </div>
        </header>
    );
};

export default Header;
