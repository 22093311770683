import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForward from '@material-ui/icons/ArrowForward';
import useStylesContent from '../../../useStylesContent';

const IdeaGiver: React.FC = () => {
    const classes = useStylesContent();

    return (
        <>
            <Typography variant="h2" component="h2" gutterBottom>
                Ваш стиль «Генератор Идей»
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Специализируется на&nbsp;побуждении других к&nbsp;принятию
                обязательств
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
                Воспринимает информацию через Конкретный Опыт
                и&nbsp;обрабатывает ее&nbsp;посредством Обдумывания
                и&nbsp;Наблюдения. Если это ваш стиль, возможно, вам нравятся
                ситуации, которые требуют разных идей, как, например,
                во&nbsp;время мозгового штурма. Вы&nbsp;хорошо умеете
                рассматривать конкретные ситуации с&nbsp;различных точек зрения.
                Скорее всего у&nbsp;вас обширные культурные интересы и&nbsp;вам
                нравится собирать информацию. Обучающиеся генераторы идей
                главным образом заинтересованы в&nbsp;поиске смысла.
                Лидер/руководитель должен доносить до&nbsp;них причину вещей.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Генераторы идей:
            </Typography>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Сильные стороны
                        </Typography>
                        <Typography variant="body1">
                            Развитое воображение, понимание других людей,
                            признание других людей, мозговой штурм.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Стиль работы
                        </Typography>
                        <Typography variant="body1">
                            Прояснение смысла вкладываемых усилий.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">Мотивация</Typography>
                        <Typography variant="body1">
                            Быть вовлеченным в&nbsp;важные вопросы
                            и&nbsp;создавать гармонию.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Любимый вопрос
                        </Typography>
                        <Typography variant="body1">Почему?</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="body1" className={classes.paragraph}>
                Развитое воображение и&nbsp;способность реагировать
                на&nbsp;эмоции необходимы для достижения высоких результатов
                в&nbsp;сферах искусства, организации досуга и&nbsp;развлечений,
                а&nbsp;также обслуживания.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Люди с&nbsp;данным стилем обучения:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        интегрируют внешний опыт с&nbsp;собственным;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        учатся, слушая идеи и&nbsp;обмениваясь ими;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        являются творческими мыслителями, верящими
                        в&nbsp;собственный опыт;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        отличаются способностью рассматривать опыт
                        с&nbsp;различных точек зрения;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        ценят способность доходить до&nbsp;самой сути;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        работают для гармонии, интересуются культурой;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        хотят лично участвовать во&nbsp;всем, любят наблюдать
                        за&nbsp;другими;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        ценят в&nbsp;других обязательность;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        во&nbsp;всем ищут смысл и&nbsp;объяснения.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Когда они руководят другими:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        &laquo;расцветают&raquo;, когда есть время
                        на&nbsp;генерирование ценных идей;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        руководят так, как подсказывают им&nbsp;сердце,
                        вовлекают других людей в&nbsp;принятие решений;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        с&nbsp;подчиненными демонстрируют доверие
                        и&nbsp;участие;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        работают на&nbsp;единство в&nbsp;организации;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        знают о&nbsp;социальных факторах, влияющих
                        на&nbsp;развитие человека;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        нуждаются/хотят работать с&nbsp;сотрудниками,
                        оказывающими им&nbsp;поддержку и&nbsp;разделяющими
                        их&nbsp;ощущение собственного предназначения.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Они также
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        решают задачи, размышляя сначала самостоятельно,
                        а&nbsp;затем в&nbsp;рамках мозгового штурма
                        с&nbsp;другими;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        заинтересованы в&nbsp;содействии личностному развитию;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        стараются помогать другим понять свои слабые
                        и&nbsp;сильные стороны;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        воспринимают знания как способ найти свое естественное
                        состояние;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        поощряют естественность в&nbsp;других;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        любят обсуждения, коллективную работу и&nbsp;честную
                        обратную связь о&nbsp;чувствах;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        заботятся о&nbsp;других и&nbsp;стараются вовлекать
                        их&nbsp;в&nbsp;совместную работу;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        способны сконцентрироваться на&nbsp;значимых целях;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        могут испытывать страх под давлением, также
                        в&nbsp;некоторые моменты им&nbsp;не&nbsp;хватает
                        решительности.
                    </Typography>
                </li>
            </ul>
        </>
    );
};

export default IdeaGiver;
