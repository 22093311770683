import React from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ListItemText from '@material-ui/core/ListItemText';

import { ReactComponent as Illustration } from '../../assets/img/illustration.svg';
import { ReactComponent as Illustration2 } from '../../assets/img/illustration2.svg';
import Header from '../Header';
import Button from '../Button';

import CustomTheme from '../../customTheme';
import useStylesContent from '../../useStylesContent';
import { IQuestionsLocalData } from '../../interfaces';
import PAGES from '../../constants';

const useStyles = makeStyles({
    root: {},

    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',

        [CustomTheme.breakpoints.up('md')]: {
            maxWidth: '720px',
            margin: '0 auto',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            maxWidth: '1134px',
        },
    },

    title: {
        margin: '0 0 24px',
        textAlign: 'center',

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '40px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginBottom: '60px',
        },
    },

    illustration: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        maxWidth: '400px',
        margin: '0 auto 24px',

        [CustomTheme.breakpoints.up('sm')]: {
            maxWidth: '500px',
            marginBottom: '40px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            maxWidth: '650px',
            marginBottom: '60px',
        },
    },

    illustrationSvg: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        verticalAlign: 'top',
        userSelect: 'none',

        [CustomTheme.breakpoints.up('md')]: {},
    },

    description: {
        margin: '0 0 8px',

        [CustomTheme.breakpoints.up('sm')]: {
            maxWidth: 'none',
            marginBottom: '37px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginBottom: '60px',
        },
    },

    controls: {
        width: '100%',

        [CustomTheme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '40px',
            flexWrap: 'wrap',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginBottom: '60px',
        },

        '& button': {
            marginBottom: '8px',

            [CustomTheme.breakpoints.up('sm')]: {
                marginBottom: 0,
                marginLeft: '8px',
                marginRight: '8px',
            },

            [CustomTheme.breakpoints.up('lg')]: {
                marginLeft: '12px',
                marginRight: '12px',
            },
        },
    },

    controlsText: {
        marginBottom: '8px',
        textAlign: 'center',
        flex: '0 0 100%',

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '12px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginBottom: '16px',
        },
    },

    divider: {
        width: '100%',
        margin: '16px 0 24px',

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '40px',
            marginTop: 0,
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginBottom: '60px',
        },
    },
});

interface IProps {
    handleSetStep(direction: string): void;
    setStep(step: number): void;
    questionsLocalArray: IQuestionsLocalData[] | undefined;
}

const Start: React.FC<IProps> = ({
    handleSetStep,
    questionsLocalArray,
    setStep,
}) => {
    const classes = useStyles();
    const classesContent = useStylesContent();

    const isProcess =
        questionsLocalArray && questionsLocalArray[0].answers.length;

    const isActiveStep = () => {
        if (!questionsLocalArray) {
            return;
        }

        const getAnswersDone = questionsLocalArray.filter((question) =>
            question.answers.every((item) => item.answer.length)
        );
        const index = getAnswersDone.length;

        if (questionsLocalArray.length > index) {
            return index + 1;
        } else {
            return index;
        }
    };

    const handleStart = () => {
        localStorage.setItem('isResult', 'false');
        handleSetStep('next');
    };

    const handleReStart = () => {
        localStorage.setItem('isResult', 'false');
        handleSetStep('restart');
    };

    const isResultPage = () => {
        const isResult = localStorage.getItem('isResult');

        return isResult === 'true';
    };

    const handleGoToActiveStep = () => {
        const activeStep = isActiveStep();

        if (typeof activeStep !== 'number') {
            return;
        }

        if (isResultPage()) {
            setStep(PAGES.result);
            localStorage.setItem('step', PAGES.result.toString());
        } else {
            setStep(activeStep);
            localStorage.setItem('step', activeStep.toString());
        }
    };

    const renderControls = () => {
        return isProcess ? (
            <div className={classes.controls}>
                <Typography variant="body1" className={classes.controlsText}>
                    {isResultPage()
                        ? `Вы${'\u00A0'}уже прошли тест`
                        : `Вы${'\u00A0'}уже начали проходить тест и${'\u00A0'}не${'\u00A0'}завершили
                    его`}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoToActiveStep}
                    mobileFullWidth
                >
                    {isResultPage() ? `K${'\u00A0'}результатам` : 'Продолжить'}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReStart}
                    mobileFullWidth
                >
                    Начать с начала
                </Button>
            </div>
        ) : (
            <div className={classes.controls}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStart}
                    mobileFullWidth
                >
                    Пройти Тест
                </Button>
            </div>
        );
    };

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <Header />
            </Grid>

            <Grid item xs={12}>
                <div className={classes.main}>
                    <div className={classes.illustration}>
                        <Illustration className={classes.illustrationSvg} />
                    </div>

                    <div className={classes.title}>
                        <Typography variant="h3" component="h2" gutterBottom>
                            Тест Дэвида Колба
                        </Typography>

                        <Typography variant="h1">
                            Выявление предпочтений в&nbsp;обучении
                        </Typography>
                    </div>

                    {renderControls()}

                    <Divider className={classes.divider} />

                    <div className={classes.description}>
                        <Typography
                            variant="body1"
                            className={classesContent.paragraph}
                        >
                            Тест Дэвида Колба разработан для повышения
                            эффективности обучения через выявление
                            предпочитаемого стиля. Вас ждет 9&nbsp;заданий,
                            которые помогут определить ваши интересы
                            и&nbsp;склонности.
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classesContent.paragraph}
                        >
                            Эффективность учебной деятельности сильно зависит
                            от&nbsp;персонального стиля обучения. И&nbsp;это
                            касается не&nbsp;только получения новых знаний,
                            но&nbsp;и, например, процесса адаптации
                            на&nbsp;новом месте работы. Если вы&nbsp;понимаете
                            свой стиль, то&nbsp;можете подобрать наиболее
                            подходящие вам методики и&nbsp;способы обучения.
                        </Typography>
                        <Typography variant="h3" component="h3" gutterBottom>
                            В&nbsp;результате теста вы&nbsp;узнаете:
                        </Typography>
                        <ul className={classesContent.listNative}>
                            <li className={classesContent.listNativeItem}>
                                <Typography variant="body1">
                                    какой стиль обучения вам подходит;
                                </Typography>
                            </li>
                            <li className={classesContent.listNativeItem}>
                                <Typography variant="body1">
                                    сильные и&nbsp;слабые стороны своего
                                    характера;
                                </Typography>
                            </li>
                            <li className={classesContent.listNativeItem}>
                                <Typography variant="body1">
                                    интеллектуальные и&nbsp;личностные
                                    возможности.
                                </Typography>
                            </li>
                        </ul>
                    </div>
                    <div className={classes.description}>
                        <div className={classes.illustration}>
                            <Illustration2
                                className={classes.illustrationSvg}
                            />
                        </div>

                        <Typography variant="h3" component="h3" gutterBottom>
                            При прохождении теста
                        </Typography>

                        <List
                            className={classNames(
                                classesContent.list,
                                classesContent.listMain
                            )}
                        >
                            <ListItem className={classesContent.listItem}>
                                <ListItemIcon
                                    className={classesContent.listItemIcon}
                                >
                                    <ArrowForward />
                                </ListItemIcon>
                                <ListItemText
                                    className={classesContent.listItemText}
                                >
                                    <Typography variant="subtitle1">
                                        расслабьтесь, представьте себя
                                        в&nbsp;ситуации, когда обучаетесь
                                        чему-то новому;
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classesContent.listItem}>
                                <ListItemIcon
                                    className={classesContent.listItemIcon}
                                >
                                    <ArrowForward />
                                </ListItemIcon>
                                <ListItemText
                                    className={classesContent.listItemText}
                                >
                                    <Typography variant="subtitle1">
                                        будьте честны;
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classesContent.listItem}>
                                <ListItemIcon
                                    className={classesContent.listItemIcon}
                                >
                                    <ArrowForward />
                                </ListItemIcon>
                                <ListItemText
                                    className={classesContent.listItemText}
                                >
                                    <Typography variant="subtitle1">
                                        не&nbsp;тратьте много времени
                                        на&nbsp;задания, отвечайте спонтанно.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </div>

                    <Divider className={classes.divider} />

                    {renderControls()}
                </div>
            </Grid>
        </Grid>
    );
};

export default Start;
