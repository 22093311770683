import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './components/App/';

const tagManagerArgs = {
    gtmId:
        process.env.REACT_APP_VERSION === 'smarthead'
            ? 'GTM-M6RL936'
            : 'GTM-TGL9RLM',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
