import React, { useEffect, useState, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { Svg } from '@svgdotjs/svg.js';

import Grid from '@material-ui/core/Grid';
import MaterialCollapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import customTheme from '../../customTheme';

import Header from '../Header';
import Chart from '../Chart';
import Collapse from '../Collapse';
import Form from '../Form';
import Button from '../Button';

import { getInfoMaxSide } from '../../getData/getInfoCharts';
import getResults from '../../getData/getResults';
import getPDF from './pdf/getPdf';

import { IResult, IMaxSides } from '../../interfaces';
import resultsContentData from '../../data/resultsContentData';
import ComponentsContent from './ResultsContent/componentsContent';
import drawChart from '../Chart/drawChart';

interface IProps {
    handleSetStep(direction: string): void;
}

const useStyles = makeStyles({
    buttonStack: {
        marginBottom: '16px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '40px',
        },

        '& button': {
            marginBottom: '12px',

            '&:last-child': {
                marginBottom: '0',
            },

            [customTheme.breakpoints.up('sm')]: {
                width: 'auto',
                marginRight: '20px',
                marginBottom: '0',
            },
        },
    },
    activeContent: {
        marginBottom: '24px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '56px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '104px',
        },
    },
    otherContent: {
        marginBottom: '24px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '56px',
        },

        [customTheme.breakpoints.up('md')]: {
            marginBottom: '60px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '104px',
        },
    },
});

const Result: React.FC<IProps> = ({ handleSetStep }) => {
    const classes = useStyles();
    const chartRef = useRef<HTMLDivElement | null>(null);
    const [result, setResult] = useState<IResult | null>(null);
    const [isMount, setIsMount] = useState(false);
    const [maxSides, setMaxSides] = useState<IMaxSides | null>(null);
    const [formVisibleStates, setFormVisibleStates] = React.useState<boolean[]>(
        []
    );
    const [userName, setUserName] = useState('');
    const [svgDraw, setSvgDraw] = useState<Svg>();

    const handleReStart = () => {
        localStorage.setItem('isResult', 'false');
        handleSetStep('restart');
    };

    const toggleFormVisible = (index: number) => {
        setFormVisibleStates((prevState) => {
            const newState = [...prevState];

            newState[index] = !newState[index];

            return newState;
        });
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
    };

    const handleDownloadRequest = () => {
        if (!maxSides || !result || !chartRef.current) {
            return;
        }

        const svgChart = drawChart(result, chartRef.current, true);

        getPDF({
            result: maxSides.startIndex,
            userName,
            chartImage: svgChart,
        });
    };

    useEffect(() => {
        if (chartRef.current && result) {
            const svgChart = drawChart(result, chartRef.current, false);

            setSvgDraw(svgChart);
        }
    }, [result]);

    useEffect(() => {
        if (!isMount) {
            localStorage.setItem('isResult', 'true');
            setResult(getResults());
            setMaxSides(getInfoMaxSide());
            setIsMount(true);

            TagManager.dataLayer({
                dataLayer: {
                    event: 'resultShow',
                },
            });
        }
    }, [isMount]);

    const ActiveComponent = maxSides && ComponentsContent[maxSides.startIndex];

    const getOtherResults = () => {
        const activeIndex = maxSides && maxSides.startIndex;

        return resultsContentData.filter((item) => item.id !== activeIndex);
    };

    return (
        <Grid container spacing={3} justify="center">
            <Grid container item xs={12} md={10}>
                <Grid item xs={12}>
                    <Header internal handleSetStep={handleSetStep} />
                </Grid>

                <Grid item xs={8} sm={12}>
                    <Typography variant="overline" gutterBottom>
                        Результат теста дэвида колба
                    </Typography>

                    <Typography variant="h3" component="h1" gutterBottom>
                        Выявление предпочтений в&nbsp;обучении
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.buttonStack}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleReStart}
                            mobileFullWidth
                        >
                            пройти тест еще раз
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={formVisibleStates[0]}
                            mobileFullWidth
                            onClick={() => toggleFormVisible(0)}
                        >
                            сохранить результат
                        </Button>
                    </div>
                    <MaterialCollapse in={formVisibleStates[0]}>
                        <Form
                            value={userName}
                            indexForm={0}
                            onHide={toggleFormVisible}
                            onChange={handleFormChange}
                            onDownload={handleDownloadRequest}
                        />
                    </MaterialCollapse>
                    <Typography variant="h1" component="h1" gutterBottom>
                        Профиль предпочтений в&nbsp;обучении
                    </Typography>
                    <Chart result={result} ref={chartRef} svgDraw={svgDraw} />
                    <div className={classes.activeContent}>
                        {ActiveComponent && <ActiveComponent />}
                    </div>
                    <Typography variant="h2" component="h2" gutterBottom>
                        Другие стили
                    </Typography>
                    <div className={classes.otherContent}>
                        <Collapse data={getOtherResults()} />
                    </div>
                    <div className={classes.buttonStack}>
                        <Button
                            color="primary"
                            variant="outlined"
                            mobileFullWidth
                            onClick={handleReStart}
                        >
                            пройти тест еще раз
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            mobileFullWidth
                            disabled={formVisibleStates[1]}
                            onClick={() => toggleFormVisible(1)}
                        >
                            сохранить результат
                        </Button>
                    </div>
                    <MaterialCollapse in={formVisibleStates[1]}>
                        <Form
                            value={userName}
                            indexForm={1}
                            onHide={toggleFormVisible}
                            onChange={handleFormChange}
                            onDownload={handleDownloadRequest}
                        />
                    </MaterialCollapse>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Result;
