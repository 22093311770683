import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomTheme from '../../customTheme';

import Header from '../Header';
import Question from '../Question';
import Button from '../Button';

import answerOptionsData from '../../data/answerOptionsData';
import initialQuestionsLocalData from '../../data/questionsLocalData';
import PAGES from '../../constants';
import { IQuestionsLocalData } from '../../interfaces';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
    question: {
        margin: '0 0 18px',
        padding: '20px 0 0',

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '56px',
            paddingTop: '56px',
        },
    },

    controls: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    restart: {
        paddingTop: '16px',
    },

    divider: {
        width: '100%',
        display: 'none',

        [CustomTheme.breakpoints.up('sm')]: {
            display: 'block',
            margin: '24px 0',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            marginTop: '40px',
        },
    },
});

interface IProps {
    handleSetStep(direction: string): void;
    step: number;
}

const Questions: React.FC<IProps> = ({ handleSetStep, step }) => {
    const classes = useStyles();
    const [isQuestionResolved, setIsQuestionResolved] = useState(false);
    const [isRestart, setIsRestart] = useState(false);
    const isLastQuestion = step === PAGES.result - 1;
    const currentQuestion = answerOptionsData[step - PAGES.startQuestion];

    const getQuestionLocal = () => {
        return JSON.parse(
            localStorage.getItem('questions') as string
        ) as IQuestionsLocalData[];
    };

    const addNewQuestionLocal = () => {
        const questionsLocal = getQuestionLocal();

        if (
            questionsLocal.length > step ||
            questionsLocal.length >= PAGES.endQuestion
        ) {
            return;
        }

        questionsLocal.push(initialQuestionsLocalData);
        localStorage.setItem('questions', JSON.stringify(questionsLocal));
    };

    const removeQuestionLocal = () => {
        const questionsLocal = getQuestionLocal();
        const lastObject = questionsLocal[questionsLocal.length - 1];
        const isNotRemove = lastObject.answers.some(
            (item) => item.answer.length
        );

        if (isNotRemove || step <= PAGES.startQuestionsIndex) {
            return;
        }

        questionsLocal.pop();

        localStorage.setItem('questions', JSON.stringify(questionsLocal));
    };

    const handleNext = () => {
        handleSetStep('next');

        addNewQuestionLocal();
    };

    const handlePrev = () => {
        handleSetStep('prev');

        removeQuestionLocal();
    };

    const handleChangeIsRestart = (status: boolean) => {
        setIsRestart(status);
    };

    const handleReStart = () => {
        handleChangeIsRestart(true);
        handleSetStep('restart');
    };

    return (
        <Grid container spacing={3} justify="center">
            <Grid container item xs={12} sm={10} md={6} lg={4}>
                <Grid item xs={12}>
                    <Header internal handleSetStep={handleSetStep} />
                </Grid>

                <Grid item xs={8} sm={12}>
                    <Typography variant="overline" component="div" gutterBottom>
                        Тест Дэвида Колба
                    </Typography>

                    <Typography variant="h3" component="h1" gutterBottom>
                        Выявление предпочтений в&nbsp;обучении
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={12}>
                    <Typography variant="body1">
                        Сравните и перетащите по слову в свободные поля,
                        распределяя их по предпочтениям в обучении
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.question}>
                    <Question
                        key={currentQuestion.id}
                        answerOptionData={currentQuestion.data}
                        numberQuestion={currentQuestion.id}
                        onChange={(isFinished) => {
                            setIsQuestionResolved(isFinished);
                        }}
                        isRestart={isRestart}
                        handleChangeIsRestart={handleChangeIsRestart}
                    />
                </Grid>

                <Grid item xs={12} className={classes.controls}>
                    <div>
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={
                                <ArrowBackIos style={{ fontSize: 13 }} />
                            }
                            onClick={handlePrev}
                            mobileIcon={true}
                        >
                            Назад
                        </Button>
                    </div>

                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            endIcon={
                                <ArrowForwardIos style={{ fontSize: 13 }} />
                            }
                            onClick={handleNext}
                            mobileIcon={!isLastQuestion}
                            disabled={!isQuestionResolved}
                        >
                            {isLastQuestion ? 'К результатам' : 'Далее'}
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12} className={classes.restart}>
                    <Divider className={classes.divider} />
                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={handleReStart}
                    >
                        Сбросить всё и начать тест с начала
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Questions;
