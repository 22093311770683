import { IQuestionsLocalData, IResult } from '../interfaces';

const initialResult: IResult = {
    xPlus: 0,
    yMinus: 0,
    xMinus: 0,
    yPlus: 0,
};

const getResults = (): IResult => {
    const questionsLocalObject = JSON.parse(
        localStorage.getItem('questions') as string
    ) as IQuestionsLocalData[];
    const newResult = { ...initialResult };

    questionsLocalObject.forEach((itemQuestions, index) => {
        itemQuestions.answers.forEach((answer, indexAnswer) => {
            if (answer.answer[0].type === 'CO' && index !== 1 && index !== 7) {
                if (indexAnswer === 0) {
                    newResult.xPlus += 4;
                }
                if (indexAnswer === 1) {
                    newResult.xPlus += 3;
                }
                if (indexAnswer === 2) {
                    newResult.xPlus += 2;
                }
                if (indexAnswer === 3) {
                    newResult.xPlus += 1;
                }
            }

            if (answer.answer[0].type === 'AT' && index !== 4 && index !== 5) {
                if (indexAnswer === 0) {
                    newResult.yMinus += 4;
                }
                if (indexAnswer === 1) {
                    newResult.yMinus += 3;
                }
                if (indexAnswer === 2) {
                    newResult.yMinus += 2;
                }
                if (indexAnswer === 3) {
                    newResult.yMinus += 1;
                }
            }

            if (answer.answer[0].type === 'AE' && index !== 7 && index !== 8) {
                if (indexAnswer === 0) {
                    newResult.xMinus += 4;
                }
                if (indexAnswer === 1) {
                    newResult.xMinus += 3;
                }
                if (indexAnswer === 2) {
                    newResult.xMinus += 2;
                }
                if (indexAnswer === 3) {
                    newResult.xMinus += 1;
                }
            }

            if (answer.answer[0].type === 'CE' && index !== 2 && index !== 5) {
                if (indexAnswer === 0) {
                    newResult.yPlus += 4;
                }
                if (indexAnswer === 1) {
                    newResult.yPlus += 3;
                }
                if (indexAnswer === 2) {
                    newResult.yPlus += 2;
                }
                if (indexAnswer === 3) {
                    newResult.yPlus += 1;
                }
            }
        });
    });

    return newResult;
};

export default getResults;
