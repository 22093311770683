import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForward from '@material-ui/icons/ArrowForward';
import useStylesContent from '../../../useStylesContent';

const Explainer: React.FC = () => {
    const classes = useStylesContent();

    return (
        <>
            <Typography variant="h2" component="h2" gutterBottom>
                Ваш стиль «Теоретик»
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Специализируется на&nbsp;структурировании и&nbsp;создании
                концепций
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
                Воспринимает информацию путем Абстрактной Концептуализации
                и&nbsp;обрабатывает ее&nbsp;через Обдумывание и&nbsp;Наблюдение.
                Если это ваш стиль обучения, вы, вероятно, менее
                сконцентрированы на&nbsp;людях и&nbsp;больше заинтересованы
                в&nbsp;абстрактных идеях и&nbsp;концепциях. Вам дано отлично
                понимать информацию самого разного характера и&nbsp;облекать
                ее&nbsp;в&nbsp;четкие логические формы. Иногда логическая
                стройность теорий может представляться важнее
                их&nbsp;практической ценности. Обучающиеся теоретики главным
                образом заинтересованы в&nbsp;фактах, поскольку именно факты
                приводят к&nbsp;концептуальному пониманию явления или задачи.
                Лидер/руководитель должен предоставлять им&nbsp;факты,
                способствующие более глубокому пониманию.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Теоретики:
            </Typography>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Сильные стороны
                        </Typography>
                        <Typography variant="body1">
                            Планирование, создание концепций и&nbsp;моделей,
                            определение проблем, развитие теорий
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Стиль работы
                        </Typography>
                        <Typography variant="body1">Продумывание</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">Мотивация</Typography>
                        <Typography variant="body1">
                            Признание интеллектуальных способностей
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Любимый вопрос
                        </Typography>
                        <Typography variant="body1">Что?</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="body1" className={classes.paragraph}>
                Данный стиль обучения помогает достигать высоких результатов
                в&nbsp;информационной и&nbsp;научной сферах работы.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Когда они руководят другими:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        успешны в&nbsp;сборе разрозненных фактов в&nbsp;связные
                        теории;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        руководят через принципы и&nbsp;процедуры;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        с&nbsp;подчиненными демонстрируют уверенное убеждение
                        и&nbsp;знание фактов;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        работают над тем, чтобы их&nbsp;организация
                        воспринималась как оплот традиций и&nbsp;престижа;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        нуждаются/хотят работать с&nbsp;хорошо организованными
                        сотрудниками, им&nbsp;требуется записывать важную
                        информацию. Выполняют принятые решения.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Они также
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        решают проблемы, применяя логику и&nbsp;рациональность;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        заинтересованы в&nbsp;передаче знаний;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        пытаются быть максимально точными и&nbsp;знающими;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        воспринимают знания как способ углубить понимание;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        поощряют других выделяться из&nbsp;общей массы;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        любят факты и&nbsp;детали, организованное
                        и&nbsp;последовательное мышление;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        приверженцы традиционных подходов, стремятся передать
                        другим любовь к&nbsp;точным знаниям;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        верят в&nbsp;рациональное использование властных
                        полномочий;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        иногда могут препятствовать творческому выражению,
                        доминируя над другими.
                    </Typography>
                </li>
            </ul>
        </>
    );
};

export default Explainer;
