const SUMMARIZER = [
    {
        style: 'h2',
        text: 'Ваш стиль «Испытатель»',
    },
    {
        style: 'h3',
        text:
            'Специализируется на претворении планов в жизнь и достижениях результатов.',
    },
    {
        style: 'body1',
        text:
            'Воспринимает информацию путем Абстрактной концептуализации и обрабатывает ее через Активное экспериментирование.',
    },
    {
        style: 'body1',
        text:
            'Если вы предпочитаете такой стиль обучения, вы умеете решать задачи и принимать решения, основываясь на поисках ответов на вопросы или решений проблем. Вы умеете находить практическое применение идеям и теориям и, скорее всего, будете выполнять технические задачи лучше, чем решать вопросы социального и межличностного характера.',
    },
    {
        style: 'body1',
        text:
            'Обучающихся испытателей главным образом интересует, как все работает. Лидер/руководитель должен дать им возможность для исследования.',
    },
    {
        style: 'list',
        margin: [12, 13, 0, 9],
        ul: [
            [
                { style: 'subtitle', text: 'Сильные стороны' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text:
                        'Решение задач, принятие решений, дедуктивное обоснование, определение проблем, практическое применение идей.',
                },
            ],
            [
                { style: 'subtitle', text: 'Стиль работы' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text:
                        'Фактические данные, собранные через личное участие и практический опыт.',
                },
            ],
            [
                { style: 'subtitle', text: 'Мотивация' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text:
                        'Приводить их собственное видение настоящего в соответствие с тем, каким они видят безопасное будущее.',
                },
            ],
            [
                { style: 'subtitle', text: 'Любимый вопрос' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Как?',
                },
            ],
        ],
    },
    {
        style: 'body1',
        text:
            'Такие навыки обучения важны для достижения высоких результатов в узкоспециализированных и технологичных сферах.',
    },
    {
        style: 'h4',
        text: 'Люди с данным стилем обучения:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Интегрируют теорию и практику;',
            },
            {
                style: 'body1',
                text: 'Стремятся к созданию целостных картин;',
            },
            {
                style: 'body1',
                text: 'Учатся, проверяя теории и применяя здравый смысл;',
            },
            {
                style: 'body1',
                text:
                    'Прагматичны и считают, что если что-то работает, то это и нужно использовать;',
            },
            {
                style: 'body1',
                text:
                    'Рационально подходят к решению проблем, не любят получать готовые ответы;',
            },
            {
                style: 'body1',
                text: 'Сразу переходят к делу, не любят долгие церемонии;',
            },
            {
                style: 'body1',
                text: 'Ценят стратегическое мышление, конкретные идеи;',
            },
            {
                style: 'body1',
                text: 'Должны знать, как все устроено и работает;',
            },
            {
                style: 'body1',
                text: 'Меняют реальность, переходя сразу к самой сути;',
            },
            {
                style: 'body1',
                text: 'Стремятся к извлечению пользы и получению результатов;',
            },
            {
                style: 'body1',
                text: 'Могут иногда казаться властными и равнодушными.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Когда они руководят другими:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Успешны в составлении планов и определении сроков;',
            },
            {
                style: 'body1',
                text:
                    'Руководя, демонстрируют собственную напористость, обращают внимание других на качество работы;',
            },
            {
                style: 'body1',
                text:
                    'С подчиненными демонстрируют подход кнута и пряника (чем меньше правил, тем лучше, однако контролируют их исполнение);',
            },
            {
                style: 'body1',
                text:
                    'Работают на повышение продуктивности и стабильности своей организации;',
            },
            {
                style: 'body1',
                text:
                    'Нуждаются/хотят работать с сотрудниками, которые ориентированы на выполнение задач и действуют быстро.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Они также:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Решают задачи, принимая односторонние решения;',
            },
            {
                style: 'body1',
                text: 'Заинтересованы в производительности и компетенции;',
            },
            {
                style: 'body1',
                text:
                    'Пытаются дать другим навыки, которые пригодятся им в жизни;',
            },
            {
                style: 'body1',
                text:
                    'Считают знания инструментом, который позволяет людям самостоятельно находить свой путь;',
            },
            {
                style: 'body1',
                text: 'Поощряют практическое применение;',
            },
            {
                style: 'body1',
                text: 'Любят технические навыки и практическую деятельность;',
            },
            {
                style: 'body1',
                text: 'Считают, что лучший способ определяется научным путем;',
            },
            {
                style: 'body1',
                text: 'Используют измеряемые поощрения;',
            },
            {
                style: 'body1',
                text: 'Иногда могут быть негибкими и замкнутыми;',
            },
            {
                style: 'body1',
                text: 'Могут испытывать недостаток навыков командной работы.',
            },
        ],
    },
];

export default SUMMARIZER;
