const resultsContentData = [
    {
        id: 0,
        name: 'Теоретик',
        nameEng: 'Explainer',
    },
    {
        id: 1,
        name: 'Испытатель',
        nameEng: 'Summarizer',
    },
    {
        id: 2,
        name: 'Экспериментатор',
        nameEng: 'Tester',
    },
    {
        id: 3,
        name: 'Генератор идей',
        nameEng: 'Idea giver',
    },
];

export default resultsContentData;
