import { Svg, SVG } from '@svgdotjs/svg.js';

import {
    getPointsTriangle,
    getPointsPolygon,
    getInfoMaxSide,
    getAreaPercents,
    isPointMaxSide,
} from '../../getData/getInfoCharts';

import coordinateAxesData from '../../data/coordinateAxesData';
import { IMaxSides, IResult } from '../../interfaces';

let draw: Svg | null;

const drawChart = (
    result: IResult,
    container: HTMLDivElement,
    isChartForPdf: boolean
): Svg => {
    draw = SVG();
    draw.addTo(container);

    if (isChartForPdf) {
        draw.viewbox(-170, -60, 812, 625);
    } else {
        draw.viewbox(0, 0, 525, 500);
    }

    addAxis(draw);
    addPolygons(draw);
    addActiveTicks(draw, result);
    drawTextLegend(draw);
    drawInfo(draw, isChartForPdf);

    if (isChartForPdf) {
        draw.remove();
    }

    return draw;
};

const addAxis = (draw: Svg) => {
    const groupAxis = draw.group();
    const xPlus = draw.path(
        'M525.1,250.6c0.2-0.2,0.2-0.5,0-0.7l-3.2-3.2c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l2.8,2.8l-2.8,2.8c-0.2,0.2-0.2,0.5,0,0.7c0.2,0.2,0.5,0.2,0.7,0L525.1,250.6z M251.1,250.8h273.7v-1H251.1V250.8z'
    );
    const YPlus = draw.path(
        'M250.9,0.4c-0.2-0.2-0.5-0.2-0.7,0L247,3.6c-0.2,0.2-0.2,0.5,0,0.7s0.5,0.2,0.7,0l2.8-2.8l2.8,2.8c0.2,0.2,0.5,0.2,0.7,0s0.2-0.5,0-0.7L250.9,0.4z M251.1,250.3V0.8h-1v249.5H251.1z'
    );
    const xMinus = draw.path(
        'M0.4,249.9c-0.2,0.2-0.2,0.5,0,0.7l3.2,3.2c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7l-2.8-2.8l2.8-2.8c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L0.4,249.9z M0.7,250.8h248.8v-1H0.7V250.8z'
    );
    const yMinus = draw.path(
        'M250.2,500.1c0.2,0.2,0.5,0.2,0.7,0l3.2-3.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-2.8,2.8l-2.8-2.8c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7L250.2,500.1z M250.1,250.3v249.5h1V250.3H250.1z'
    );

    groupAxis.add(xPlus).add(YPlus).add(xMinus).add(yMinus).fill('#BDBDBD');
};

const addPolygons = (draw: Svg) => {
    const polygon = draw.polygon(getPointsPolygon());
    polygon.fill('rgba(33, 33, 33, 0.1)');
    const triangle = draw.polygon(getPointsTriangle());
    triangle.fill('#fff');
    const triangle2 = draw.polygon(getPointsTriangle());
    triangle2
        .fill('rgba(41, 121, 255, 0.2)')
        .stroke({ color: '#2979ff', width: 2 });
};

const addActiveTicks = (draw: Svg, result: IResult) => {
    const tick = draw
        .defs()
        .circle(6)
        .move(250 - 3, 250 - 3);

    const setColor = (width: number): string => {
        if (isPointMaxSide(width)) {
            return '#2979ff';
        }

        return '#9e9e9e';
    };

    coordinateAxesData.xMinus.forEach((item) => {
        if (item.value === result.xMinus) {
            draw.use(tick).move(-item.width, 0).fill(setColor(item.width));
        }
    });
    coordinateAxesData.xPlus.forEach((item) => {
        if (item.value === result.xPlus) {
            draw.use(tick).move(item.width, 0).fill(setColor(item.width));
        }
    });
    coordinateAxesData.yMinus.forEach((item) => {
        if (item.value === result.yMinus) {
            draw.use(tick).move(0, item.width).fill(setColor(item.width));
        }
    });
    coordinateAxesData.yPlus.forEach((item) => {
        if (item.value === result.yPlus) {
            draw.use(tick).move(0, -item.width).fill(setColor(item.width));
        }
    });
};

const setTextLegend = (texts: string[], draw: Svg) => {
    return draw
        .defs()
        .text((add) => {
            texts.forEach((text) => {
                add.tspan(text).newLine();
            });
        })
        .font({
            fill: '#9e9e9e',
            family: 'Roboto',
            size: 14,
        })
        .addClass('chart-legend');
};

const drawTextLegend = (draw: Svg) => {
    draw.use(
        setTextLegend(
            ['Активное', 'экспериментирование', 'Active experimentation'],
            draw
        )
    )
        .font({ anchor: 'end' })
        .dmove(-15, 215);
    draw.use(
        setTextLegend(
            ['Обдумывание', 'и наблюдение', 'Considered', 'odservation'],
            draw
        )
    ).dmove(540, 215);
    draw.use(setTextLegend(['Конкретный опыт', 'Сoncrete experiences'], draw))
        .font({ anchor: 'middle' })
        .dmove(250, -55);
    draw.use(
        setTextLegend(
            ['Абстрактная концептуализация', 'Abstract thinking'],
            draw
        )
    )
        .font({ anchor: 'middle' })
        .dmove(250, 515);
};

const initTooltipElements = (draw: Svg, typeText: string) => {
    const arrow = draw
        .defs()
        .path('M9.9,0L5.6,4.3c-0.4,0.4-1,0.4-1.3,0L0,0H9.9z');
    const arrowReverse = draw
        .defs()
        .path('M0,4.6l4.3-4.3c0.4-0.4,1-0.4,1.3,0l4.3,4.3H0z');
    const textTooltip = draw
        .defs()
        .text('Вы')
        .font({
            fill: '#fff',
            family: 'Roboto',
            size: 13,
            anchor: 'start',
        })
        .dmove(15, 1);
    const tooltipBackground = draw
        .defs()
        .rect(textTooltip.length() + 32, 27)
        .fill('#212121')
        .dmove(0, 1)
        .radius(3);

    return {
        arrow,
        arrowReverse,
        textTooltip,
        tooltipBackground,
    };
};

const setTooltipMobile = (direction: string, draw: Svg, typeText: string) => {
    const {
        arrow,
        arrowReverse,
        textTooltip,
        tooltipBackground,
    } = initTooltipElements(draw, typeText);

    textTooltip.text(`Вы ${typeText.toLowerCase()}`);
    tooltipBackground.width(textTooltip.length() + 32);

    const groupTooltipMobile = draw.group();
    groupTooltipMobile.addClass('chart-tooltip-mobile');

    switch (direction) {
        case 'bottom-start':
            groupTooltipMobile
                .add(arrowReverse)
                .dmove(textTooltip.length() + 32 - 10 - 16, -3);
            groupTooltipMobile.add(tooltipBackground);
            groupTooltipMobile.add(textTooltip).dmove(-30, 140);

            return groupTooltipMobile;
        case 'bottom-end':
            groupTooltipMobile.add(arrowReverse).dmove(16, -3);
            groupTooltipMobile.add(tooltipBackground);
            groupTooltipMobile.add(textTooltip).dmove(-60, 140);

            return groupTooltipMobile;
        case 'top-start':
            groupTooltipMobile
                .add(arrow)
                .dmove(textTooltip.length() + 32 - 10 - 16, 28);
            groupTooltipMobile.add(tooltipBackground);
            groupTooltipMobile
                .add(textTooltip)
                .dmove(-72, 5)
                .font({ anchor: 'end' });

            return groupTooltipMobile;
        default:
            groupTooltipMobile.add(arrow).dmove(16, 28);
            groupTooltipMobile.add(tooltipBackground);
            groupTooltipMobile.add(textTooltip).dmove(-60, 5);

            return groupTooltipMobile;
    }
};

const setTooltip = (direction: string, draw: Svg, typeText: string) => {
    const {
        arrow,
        arrowReverse,
        textTooltip,
        tooltipBackground,
    } = initTooltipElements(draw, typeText);

    const groupTooltip = draw.group();
    groupTooltip.addClass('chart-tooltip');
    const arrowPosition =
        textTooltip.length() + 32 - (textTooltip.length() + 32) / 2 - 5;

    switch (direction) {
        case 'bottom-start':
            groupTooltip.add(arrowReverse).dmove(arrowPosition, -3);
            groupTooltip.add(tooltipBackground);
            groupTooltip.add(textTooltip).dmove(0, 140);

            return groupTooltip;
        case 'bottom-end':
            groupTooltip.add(arrowReverse).dmove(arrowPosition, -3);
            groupTooltip.add(tooltipBackground);
            groupTooltip.add(textTooltip).dmove(-50, 140);

            return groupTooltip;
        case 'top-start':
            groupTooltip.add(arrow).dmove(arrowPosition, 28);
            groupTooltip.add(tooltipBackground);
            groupTooltip.add(textTooltip).dmove(0, 5).font({ anchor: 'end' });

            return groupTooltip;
        default:
            groupTooltip.add(arrow).dmove(arrowPosition, 28);
            groupTooltip.add(tooltipBackground);
            groupTooltip.add(textTooltip).dmove(-50, 5);

            return groupTooltip;
    }
};

const setGroupInfo = (
    isActive: boolean,
    texts: string[],
    percent: number,
    direction: string,
    draw: Svg,
    isChartForPdf: boolean
) => {
    const groupInfo = draw.defs().group();

    if (isActive) {
        groupInfo.add(setTooltip(direction, draw, texts[0]));
        if (!isChartForPdf) {
            groupInfo.add(setTooltipMobile(direction, draw, texts[0]));
        }
    }

    groupInfo.add(
        draw
            .text((add) => {
                texts.forEach((text) => {
                    add.tspan(text).newLine();
                });
            })
            .font({
                fill: '#212121',
                family: 'Roboto',
                size: 18,
                weight: '500',
            })
            .dmove(0, 45)
            .addClass('chart-info')
    );

    groupInfo.add(
        draw
            .text(`${percent}%`)
            .font({
                fill: '#000',
                family: 'Roboto',
                size: 14,
                weight: '500',
            })
            .dmove(0, 100)
            .addClass('chart-info')
    );

    return groupInfo;
};

const drawInfo = (draw: Svg, isChartForPdf: boolean) => {
    const maxSides: IMaxSides = getInfoMaxSide();
    const indexSide: number = maxSides.startIndex;
    const percents: number[] = getAreaPercents();

    draw.use(
        setGroupInfo(
            indexSide === 0,
            ['Теоретик', 'Explainer'],
            percents[0],
            'bottom-start',
            draw,
            isChartForPdf
        )
    ).dmove(430, 320);
    draw.use(
        setGroupInfo(
            indexSide === 1,
            ['Испытатель', 'Summarizer'],
            percents[1],
            'bottom-end',
            draw,
            isChartForPdf
        )
    )
        .font({ anchor: 'end' })
        .dmove(70, 320);
    draw.use(
        setGroupInfo(
            indexSide === 2,
            ['Экспериментатор', 'Tester'],
            percents[2],
            'top-end',
            draw,
            isChartForPdf
        )
    )
        .font({ anchor: 'end' })
        .dmove(70, 7);
    draw.use(
        setGroupInfo(
            indexSide === 3,
            ['Генератор идей', 'Idea giver'],
            percents[3],
            'top-start',
            draw,
            isChartForPdf
        )
    ).dmove(430, 7);
};

export default drawChart;
