import React from 'react';

import { ReactComponent as Facebook } from '../../assets/img/fb.svg';
import { ReactComponent as Instagram } from '../../assets/img/ig.svg';
import { ReactComponent as Vkontakte } from '../../assets/img/vk.svg';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import CustomTheme from '../../customTheme';

const useStyles = makeStyles({
    root: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '32px',
        color: '#616161',

        [CustomTheme.breakpoints.up('sm')]: {
            flexFlow: 'row wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },

    divider: {
        width: '100%',
        marginBottom: '16px',

        [CustomTheme.breakpoints.up('sm')]: {
            flex: '0 0 100%',
        },

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '24px',
        },
    },

    copyright: {
        marginBottom: '14px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 500,

        [CustomTheme.breakpoints.up('sm')]: {
            textAlign: 'left',
            marginBottom: 0,
            fontSize: '14px',
            lineHeight: '20px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            fontSize: '16px',
            lineHeight: '24px',
        },

        '& a': {
            textDecoration: 'underline',
            color: '#000',

            '&:hover': {
                color: '#448AFF',
            },

            '&:active': {
                color: '#2979FF',
            },

            '&:visited': {
                color: '#2962FF',
            },
        },
    },

    social: {
        display: 'flex',

        '& a': {
            padding: '0 6px',
            display: 'inline-flex',

            [CustomTheme.breakpoints.up('sm')]: {
                paddingLeft: '8px',
                paddingRight: '8px',
                marginRight: '-8px',
            },

            '&:hover': {
                '& path': {
                    fill: ' #448AFF',
                },
            },

            '&:active': {
                '& path': {
                    fill: ' #2979FF',
                },
            },
        },

        '& svg': {
            [CustomTheme.breakpoints.up('sm')]: {
                width: '23px',
                height: '23px',
            },

            [CustomTheme.breakpoints.up('lg')]: {
                width: '26px',
                height: '26px',
            },
        },
    },
});

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <footer className={classes.root}>
            <Divider className={classes.divider} />
            <div className={classes.copyright}>
                Сделано в{' '}
                <Link
                    href="https://smarthead.ru/?utm_source=kolbtest&utm_medium=footer?"
                    target="_blank"
                    rel="noreferrer"
                >
                    SmartHead
                </Link>
            </div>
            <div className={classes.social}>
                <Link
                    href="https://www.facebook.com/SmartHead.ru/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Facebook />
                </Link>
                <Link
                    href="https://www.instagram.com/smarthead_official/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Instagram />
                </Link>
                <Link
                    href="https://vk.com/smarthead_ru"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Vkontakte />
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
