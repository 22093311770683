import answerOptionsData from './data/answerOptionsData';

const PAGES = Object.freeze({
    startPagesIndex: 0,
    startQuestionsIndex: 1,
    get result() {
        return answerOptionsData.length + this.startQuestionsIndex;
    },
    get startQuestion() {
        return this.startPagesIndex + this.startQuestionsIndex;
    },
    get endQuestion() {
        return this.result - this.startQuestionsIndex;
    },
});

export default PAGES;
