const answerOptionsData = [
    {
        id: 0,
        data: [
            {
                id: '0',
                content: 'приоритизировать',
                type: 'CE',
            },
            {
                id: '1',
                content: 'испытывать',
                type: 'CO',
            },
            {
                id: '2',
                content: 'резюмировать',
                type: 'AT',
            },
            {
                id: '3',
                content: 'практиковаться',
                type: 'AE',
            },
        ],
    },
    {
        id: 1,
        data: [
            {
                id: '0',
                content: 'принимать',
                type: 'CE',
            },
            {
                id: '1',
                content: 'наблюдать',
                type: 'CO',
            },
            {
                id: '2',
                content: 'анализировать',
                type: 'AT',
            },
            {
                id: '3',
                content: 'бездействовать',
                type: 'AE',
            },
        ],
    },
    {
        id: 2,
        data: [
            {
                id: '0',
                content: 'чувствовать',
                type: 'CE',
            },
            {
                id: '1',
                content: 'смотреть',
                type: 'CO',
            },
            {
                id: '2',
                content: 'взвешивать',
                type: 'AT',
            },
            {
                id: '3',
                content: 'делать',
                type: 'AE',
            },
        ],
    },
    {
        id: 3,
        data: [
            {
                id: '0',
                content: 'принимать',
                type: 'CE',
            },
            {
                id: '1',
                content: 'реагировать',
                type: 'CO',
            },
            {
                id: '2',
                content: 'оценивать',
                type: 'AT',
            },
            {
                id: '3',
                content: 'рисковать',
                type: 'AE',
            },
        ],
    },
    {
        id: 4,
        data: [
            {
                id: '0',
                content: 'интуиция',
                type: 'CE',
            },
            {
                id: '1',
                content: 'ответ',
                type: 'CO',
            },
            {
                id: '2',
                content: 'логика',
                type: 'AT',
            },
            {
                id: '3',
                content: 'вопрос',
                type: 'AE',
            },
        ],
    },
    {
        id: 5,
        data: [
            {
                id: '0',
                content: 'конкретизировать',
                type: 'CE',
            },
            {
                id: '1',
                content: 'наблюдать',
                type: 'CO',
            },
            {
                id: '2',
                content: 'теоретизировать',
                type: 'AT',
            },
            {
                id: '3',
                content: 'активизировать',
                type: 'AE',
            },
        ],
    },
    {
        id: 6,
        data: [
            {
                id: '0',
                content: 'ориентирован на сегодня',
                type: 'CE',
            },
            {
                id: '1',
                content: 'обдумывать',
                type: 'CO',
            },
            {
                id: '2',
                content: 'ориентирован на будущее',
                type: 'AT',
            },
            {
                id: '3',
                content: 'применять',
                type: 'AE',
            },
        ],
    },
    {
        id: 7,
        data: [
            {
                id: '0',
                content: 'опыт',
                type: 'CE',
            },
            {
                id: '1',
                content: 'наблюдение',
                type: 'CO',
            },
            {
                id: '2',
                content: 'идея',
                type: 'AT',
            },
            {
                id: '3',
                content: 'эксперимент',
                type: 'AE',
            },
        ],
    },
    {
        id: 8,
        data: [
            {
                id: '0',
                content: 'эмоциональный',
                type: 'CE',
            },
            {
                id: '1',
                content: 'ожидающий',
                type: 'CO',
            },
            {
                id: '2',
                content: 'рациональный',
                type: 'AT',
            },
            {
                id: '3',
                content: 'вовлеченный',
                type: 'AE',
            },
        ],
    },
];

export default answerOptionsData;
