import React from 'react';
import classNames from 'classnames';

import MaterialCollapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import customTheme from '../../customTheme';

import ComponentsContent from '../Result/ResultsContent/componentsContent';

const CustomListItem = withStyles((theme) => ({
    root: {
        padding: '12px 0 4px 0',
        borderBottom: '1px solid #e0e0e0',

        '&:hover': {
            backgroundColor: 'transparent',
        },

        '&:active': {
            backgroundColor: 'transparent',
        },
    },
}))(ListItem);

const CustomListItemText = withStyles((theme) => ({
    root: {
        margin: '2px 0',
    },
}))(ListItemText);

const useStyles = makeStyles({
    collapse: {
        marginTop: '10px',

        [customTheme.breakpoints.up('sm')]: {
            marginTop: '56px',
        },
    },
    collapseListItemActive: {
        color: '#2979ff',
    },
    arrow: {
        color: '#2979ff',
    },
});

interface IResultsInfo {
    id: number;
    name: string;
    nameEng: string;
}

interface IProps {
    data: IResultsInfo[];
}

const Collapse: React.FC<IProps> = ({ data }) => {
    const classes = useStyles();
    const [activityStates, setActivityStates] = React.useState<boolean[]>([]);

    const handleClick = (index: number) => {
        setActivityStates((prevState) => {
            const newState = [...prevState];

            newState[index] = !newState[index];

            return newState;
        });
    };

    return (
        <>
            {data.map(({ id, name }, index) => {
                const CollapseContent = ComponentsContent[id];

                return (
                    <div key={id}>
                        <CustomListItem
                            button
                            onClick={() => handleClick(index)}
                            className={classNames({
                                [classes.collapseListItemActive]:
                                    activityStates[index],
                            })}
                        >
                            <CustomListItemText>
                                <Typography variant="subtitle1">
                                    Стиль «{name}»
                                </Typography>
                            </CustomListItemText>
                            {activityStates[index] ? (
                                <ExpandLess className={classes.arrow} />
                            ) : (
                                <ExpandMore className={classes.arrow} />
                            )}
                        </CustomListItem>
                        <MaterialCollapse
                            in={activityStates[index]}
                            timeout="auto"
                            unmountOnExit
                            className={classes.collapse}
                        >
                            <CollapseContent />
                        </MaterialCollapse>
                    </div>
                );
            })}
        </>
    );
};

export default Collapse;
