const EXPLAINER = [
    {
        style: 'h2',
        text: 'Ваш стиль «Теоретик»',
    },
    {
        style: 'h3',
        text: 'Специализируется на структурировании и создании концепций',
    },
    {
        style: 'body1',
        text:
            'Воспринимает информацию путем Абстрактной Концептуализации и обрабатывает ее через Обдумывание и Наблюдение.',
    },
    {
        style: 'body1',
        text:
            'Если это ваш стиль обучения, вы, вероятно, менее сконцентрированы на людях и больше заинтересованы в абстрактных идеях и концепциях. Вам дано отлично понимать информацию самого разного характера и облекать ее в четкие логические формы. Иногда логическая стройность теорий может представляться важнее их практической ценности.',
    },
    {
        style: 'body1',
        text:
            'Обучающиеся теоретики главным образом заинтересованы в фактах, поскольку именно факты приводят к концептуальному пониманию явления или задачи. Лидер/руководитель должен предоставлять им факты, способствующие более глубокому пониманию.',
    },
    {
        style: 'list',
        margin: [12, 13, 0, 9],
        ul: [
            [
                { style: 'subtitle', text: 'Сильные стороны' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text:
                        'Планирование, создание концепций и моделей, определение проблем, развитие теорий.',
                },
            ],
            [
                { style: 'subtitle', text: 'Стиль работы' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Продумывание.',
                },
            ],
            [
                { style: 'subtitle', text: 'Мотивация' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Признание интеллектуальных способностей.',
                },
            ],
            [
                { style: 'subtitle', text: 'Любимый вопрос' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Что?',
                },
            ],
        ],
    },
    {
        style: 'body1',
        text:
            'Данный стиль обучения помогает достигать высоких результатов в информационной и научной сферах работы.',
    },
    {
        style: 'h4',
        text: 'Люди с данным стилем обучения:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text:
                    'Формируют теории и концепции, интегрируя собственные наблюдения в то, что уже известно;',
            },
            {
                style: 'body1',
                text: 'Стремятся к созданию целостных картин;',
            },
            {
                style: 'body1',
                text:
                    'Учатся через прорабатывание идей, им необходимо знать мнение экспертов;',
            },
            {
                style: 'body1',
                text: 'Ценят последовательное мышление, нуждаются в деталях;',
            },
            {
                style: 'body1',
                text:
                    'Критически анализируют информацию и собирают данные, скрупулезны и трудолюбивы;',
            },
            {
                style: 'body1',
                text: 'Если ситуация им не ясна, повторно изучат факты;',
            },
            {
                style: 'body1',
                text:
                    'Предпочитают традиционную среду обучения, им идеально подходят школы;',
            },
            {
                style: 'body1',
                text: 'Больше интересуются идеями, чем людьми;',
            },
            {
                style: 'body1',
                text:
                    'Работают над уменьшением неопределенности и не любят субъективные суждения;',
            },
            {
                style: 'body1',
                text:
                    'Предпочитают приверженность поставленным целям и применение собственных талантов, навыков, энергии и времени для достижения результатов, обозначенных целями.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Когда они руководят другими:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Успешны в сборе разрозненных фактов в связные теории;',
            },
            {
                style: 'body1',
                text: 'Руководят через принципы и процедуры;',
            },
            {
                style: 'body1',
                text:
                    'С подчиненными демонстрируют уверенное убеждение и знание фактов;',
            },
            {
                style: 'body1',
                text:
                    'Работают над тем, чтобы их организация воспринималась как оплот традиций и престижа;',
            },
            {
                style: 'body1',
                text:
                    'Нуждаются/хотят работать с хорошо организованными сотрудниками, им требуется записывать важную информацию. Выполняют принятые решения.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Они также:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Решают проблемы, применяя логику и рациональность;',
            },
            {
                style: 'body1',
                text: 'Заинтересованы в передаче знаний;',
            },
            {
                style: 'body1',
                text: 'Пытаются быть максимально точными и знающими;',
            },
            {
                style: 'body1',
                text: 'Воспринимают знания как способ углубить понимание;',
            },
            {
                style: 'body1',
                text: 'Поощряют других выделяться из общей массы;',
            },
            {
                style: 'body1',
                text:
                    'Любят факты и детали, организованное и последовательное мышление;',
            },
            {
                style: 'body1',
                text:
                    'Приверженцы традиционных подходов, стремятся передать другим любовь к точным знаниям;',
            },
            {
                style: 'body1',
                text: 'Верят в рациональное использование властных полномочий;',
            },
            {
                style: 'body1',
                text:
                    'Иногда могут препятствовать творческому выражению, доминируя над другими.',
            },
        ],
    },
];

export default EXPLAINER;
