import React, { forwardRef, useEffect, useState } from 'react';
import { Svg } from '@svgdotjs/svg.js';

import { makeStyles } from '@material-ui/core/styles';

import { IResult } from '../../interfaces';
import customTheme from '../../customTheme';

import useWindowWidth from '../../hooks/useWindowWidth';

const useStyles = makeStyles({
    chart: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '16px',

        [customTheme.breakpoints.up('sm')]: {
            padding: '50px 0 105px',
        },

        [customTheme.breakpoints.up('sm')]: {
            padding: '56px 0 56px',
        },

        [customTheme.breakpoints.up('lg')]: {
            padding: '104px 0 104px',
        },

        '& svg': {
            overflow: 'visible',
            maxWidth: '525px',
            width: '100%',
            height: '100%',

            [customTheme.breakpoints.up('sm')]: {
                maxWidth: '672px',
            },

            [customTheme.breakpoints.up('md')]: {
                maxWidth: '812px',
            },
        },

        '& .chart-info, & .chart-legend': {
            display: 'none',

            [customTheme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },

        '& .chart-tooltip': {
            display: 'none',

            [customTheme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },

        '& .chart-tooltip-mobile': {
            display: 'block',

            [customTheme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },

        '& .chart-text-tooltip-mobile': {
            display: 'block',

            [customTheme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    },
});

interface IProps {
    result: IResult | null;
    ref: React.Ref<HTMLDivElement>;
    svgDraw: Svg | undefined;
}

const Chart: React.FC<IProps> = forwardRef(function RenderChart(
    { result, svgDraw },
    ref
) {
    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(false);
    const windowWidth = useWindowWidth();

    useEffect(() => {
        setIsMobile(windowWidth < customTheme.breakpoints.values.sm);
    }, [windowWidth]);

    useEffect(() => {
        if (isMobile) {
            svgDraw?.viewbox(0, 0, 525, 500);
        } else {
            svgDraw?.viewbox(-170, -60, 812, 625);
        }
    }, [isMobile, svgDraw]);

    return <div ref={ref} className={classes.chart} />;
});

export default Chart;
