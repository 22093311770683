import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const MainContainer = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        padding: '28px 20px',

        [theme.breakpoints.up('sm')]: {
            padding: '60px 48px 22px',
        },

        [theme.breakpoints.up('md')]: {
            maxWidth: 'calc(1092px + 96px)', // container width + paddings sum
            paddingTop: '36px',
            paddingBottom: '24px',
        },

        [theme.breakpoints.up('lg')]: {
            maxWidth: 'calc(1712px + 96px)', // container width + paddings sum
        },
    },
}))(Container);

export default MainContainer;
