import React from 'react';

import DragDrop from '../DragDrop';
import Stepper from '../Stepper';

import { IAnswer } from '../../interfaces';
import PAGES from '../../constants';

interface IProps {
    answerOptionData: IAnswer[];
    numberQuestion: number;
    onChange?(isFinish: boolean): void;
    isRestart: boolean;
    handleChangeIsRestart(status: boolean): void;
}

const Question: React.FC<IProps> = ({
    answerOptionData,
    numberQuestion,
    onChange,
    isRestart,
    handleChangeIsRestart,
}) => {
    return (
        <div>
            <Stepper
                currentStep={numberQuestion}
                totalSteps={PAGES.endQuestion}
            />

            <DragDrop
                answerOptionData={answerOptionData}
                numberQuestion={numberQuestion}
                onChange={onChange}
                isRestart={isRestart}
                handleChangeIsRestart={handleChangeIsRestart}
            />
        </div>
    );
};

export default Question;
