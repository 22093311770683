import percentRound from 'percent-round';

import coordinateAxesData from '../data/coordinateAxesData';
import { IMaxSides } from '../interfaces';

import getResults from './getResults';

const CENTER = 250;

interface IChartDataObject {
    value: number;
    width: number;
}

export const getPointsOffset = (): number[] => {
    const result = getResults();

    const xPlus = coordinateAxesData.xPlus.find(
        (item) => item.value === result.xPlus
    ) as IChartDataObject;
    const yMinus = coordinateAxesData.yMinus.find(
        (item) => item.value === result.yMinus
    ) as IChartDataObject;
    const xMinus = coordinateAxesData.xMinus.find(
        (item) => item.value === result.xMinus
    ) as IChartDataObject;
    const yPlus = coordinateAxesData.yPlus.find(
        (item) => item.value === result.yPlus
    ) as IChartDataObject;

    return [xPlus.width, yMinus.width, xMinus.width, yPlus.width];
};

export const getAreaSides = (): number[] => {
    const offsets = getPointsOffset();

    return [
        offsets[0] * offsets[1],
        offsets[1] * offsets[2],
        offsets[2] * offsets[3],
        offsets[3] * offsets[0],
    ];
};

export const getAreaPercents = (): number[] => {
    const areaSides = getAreaSides();
    const totalArea = areaSides.reduce((a, b) => a + b, 0);

    const percentsArea = areaSides.map((item) =>
        totalArea !== 0 ? (item * 100) / totalArea : totalArea
    );

    return percentRound(percentsArea);
};

export const getInfoMaxSide = (): IMaxSides => {
    const pointsOffset = getPointsOffset();
    const areaSides = getAreaSides();
    const maxSide = Math.max(...areaSides);
    const startIndexMaxSide = areaSides.indexOf(maxSide);

    if (areaSides.length - 1 === startIndexMaxSide) {
        return {
            points: [pointsOffset[startIndexMaxSide], pointsOffset[0]],
            startIndex: startIndexMaxSide,
        };
    }

    return {
        points: [
            pointsOffset[startIndexMaxSide],
            pointsOffset[startIndexMaxSide + 1],
        ],
        startIndex: startIndexMaxSide,
    };
};

export const getPointsPolygon = (): string => {
    const pointsOffset = getPointsOffset();

    return `${CENTER + pointsOffset[0]}, ${CENTER} ${CENTER}, ${
        CENTER + pointsOffset[1]
    } ${CENTER - pointsOffset[2]}, ${CENTER} ${CENTER}, ${
        CENTER - pointsOffset[3]
    }`;
};

export const getPointsTriangle = (): string => {
    const maxSides = getInfoMaxSide();

    switch (maxSides.startIndex) {
        case 1:
            return `${CENTER}, ${CENTER + maxSides.points[0]} ${
                CENTER - maxSides.points[1]
            }, ${CENTER} ${CENTER}, ${CENTER}`;
        case 2:
            return `${CENTER - maxSides.points[0]}, ${CENTER} ${CENTER}, ${
                CENTER - maxSides.points[1]
            } ${CENTER}, ${CENTER}`;
        case 3:
            return `${CENTER}, ${CENTER - maxSides.points[0]} ${
                CENTER + maxSides.points[1]
            }, ${CENTER} ${CENTER}, ${CENTER}`;

        default:
            return `${CENTER + maxSides.points[0]}, ${CENTER} ${CENTER}, ${
                CENTER + maxSides.points[1]
            } ${CENTER}, ${CENTER}`;
    }
};

export const isPointMaxSide = (width: number) => {
    const maxSides = getInfoMaxSide();

    return maxSides && maxSides.points.some((point) => point === width);
};
