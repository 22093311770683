import { createMuiTheme } from '@material-ui/core/styles';
import { grey, common } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

const customTheme = createMuiTheme({
    palette: {
        background: {
            default: common['white'],
        },
    },
    typography: {
        fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif',
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1025,
            lg: 1600,
            xl: 1920,
        },
    },
});

customTheme.typography.h1 = {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 500,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '40px',
        lineHeight: '52px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '60px',
        lineHeight: '72px',
    },
};

customTheme.typography.h2 = {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: '40px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '48px',
        lineHeight: '72px',
    },
};

// H3 === Title
customTheme.typography.h3 = {
    fontSize: '16px', // Title in Figma: 14px
    lineHeight: '24px', // Title in Figma: 16px
    fontWeight: 500,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '32px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '40px',
        lineHeight: '52px',
    },

    '&.MuiTypography-gutterBottom': {
        marginBottom: '10px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '18px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '15px',
        },
    },
};

customTheme.typography.h4 = {
    fontSize: '16px', // Just like H3. A bug in Figma
    lineHeight: '24px', // Just like H3. A bug in Figma
    fontWeight: 500,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '32px',
        lineHeight: '48px',
    },
};

customTheme.typography.subtitle1 = {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '27px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '28px',
        lineHeight: '42px',
    },

    '&.MuiTypography-gutterBottom': {
        marginBottom: '12px',

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '20px',
        },
    },
};

// Like Subtitle-1 but fontWeight 400
customTheme.typography.subtitle2 = {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '27px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '28px',
        lineHeight: '42px',
    },
};

// Body-2 in Figma equals Body-1
customTheme.typography.body1 = {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '30px',
    },
};

customTheme.typography.caption = {
    fontSize: '10px', // In Figma: 12px
    lineHeight: '14px', // In Figma: 16px
    fontWeight: 400,

    [customTheme.breakpoints.up('sm')]: {
        fontSize: '12px',
        lineHeight: '16px',
    },

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: '20px',
    },
};

customTheme.typography.overline = {
    color: grey['700'],
    fontSize: '10px',
    lineHeight: '14px',
    fontWeight: 500,
    letterSpacing: '1px',
    textTransform: 'uppercase',

    [customTheme.breakpoints.up('lg')]: {
        fontSize: '12px',
        lineHeight: '16px',
    },

    '&.MuiTypography-gutterBottom': {
        marginBottom: '4px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '8px',
        },
    },
};

export default customTheme;
