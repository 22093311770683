import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { common, grey, indigo } from '@material-ui/core/colors';

import CustomTheme from '../../customTheme';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0 0 12px',
        userSelect: 'none',

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '40px',
        },
    },

    step: {
        margin: '0 20px 0 0',
    },

    dots: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    dot: {
        display: 'block',
        width: '8px',
        height: '8px',
        margin: '0 2px',
        background: indigo[500],
        border: `2px solid rgba(0, 0, 0, 0)`,
        borderRadius: '50%',
    },

    dotActive: {
        width: '10px',
        height: '10px',
        background: common['white'],
        borderColor: indigo[500],

        '& ~ $dot': {
            background: grey[400],
        },
    },
});

interface IProps {
    currentStep: number;
    totalSteps: number;
}

const Stepper: React.FC<IProps> = ({ currentStep, totalSteps }) => {
    const classes = useStyles();
    const dotsArray = new Array<number>(totalSteps).fill(0);

    return (
        <div className={classes.root}>
            <Typography className={classes.step} variant="caption">
                {currentStep + 1} / {totalSteps}
            </Typography>

            <div className={classes.dots}>
                {dotsArray.map((item, index) => (
                    <span
                        key={index}
                        className={classNames([
                            classes.dot,
                            {
                                [classes.dotActive]: index === currentStep,
                            },
                        ])}
                    />
                ))}
            </div>
        </div>
    );
};

export default Stepper;
