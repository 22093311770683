import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import customTheme from './customTheme';

const useStylesContent = makeStyles({
    list: {
        padding: '0',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '28px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '40px',
        },
    },
    listMain: {
        [customTheme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: 0,
        },
    },
    listItem: {
        padding: '0',
        marginTop: '12px',
        alignItems: 'flex-start',

        [customTheme.breakpoints.up('sm')]: {
            marginTop: '16px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginTop: '24px',
        },
    },
    listItemText: {
        margin: '0',
    },
    listItemIcon: {
        color: grey['900'],

        [customTheme.breakpoints.up('lg')]: {
            marginTop: '6px',
        },
    },
    listNative: {
        listStyle: 'none',
        paddingLeft: '24px',
        margin: '0 0 8px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '28px',
            paddingLeft: '32px',
        },

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '34px',
        },
    },
    listNativeItem: {
        position: 'relative',
        margin: '4px 0',

        [customTheme.breakpoints.up('lg')]: {
            margin: '6px 0',
        },

        '&:before': {
            content: "''",
            position: 'absolute',
            top: '7px',
            left: '-17px',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: grey['900'],

            [customTheme.breakpoints.up('sm')]: {
                top: '9px',
            },

            [customTheme.breakpoints.up('lg')]: {
                top: '13px',
            },
        },
    },
    paragraph: {
        marginBottom: '12px',

        [customTheme.breakpoints.up('sm')]: {
            marginBottom: '28px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '40px',
        },
    },
});

export default useStylesContent;
