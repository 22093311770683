const PDF_STYLES = {
    h1: {
        margin: [0, 0, 0, 20],
        fontSize: 20,
        lineHeight: 1.2,
        bold: true,
        alignment: 'left',
    },

    h2: {
        margin: [0, 0, 0, 14],
        fontSize: 16,
        lineHeight: 1.2,
        bold: true,
        alignment: 'left',
    },

    h3: {
        margin: [0, 0, 0, 10],
        fontSize: 12,
        lineHeight: 1.2,
        bold: true,
        alignment: 'left',
    },

    h4: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        lineHeight: 1.2,
        bold: true,
        alignment: 'left',
    },

    subtitle: {
        margin: 0,
        fontSize: 9,
        lineHeight: 1.2,
        bold: true,
        alignment: 'left',
    },

    overline: {
        margin: 0,
        color: '#616161',
        fontSize: 5,
        lineHeight: 1.2,
        bold: false,
        alignment: 'left',
    },

    body1: {
        margin: [0, 0, 0, 4],
        fontSize: 8,
        lineHeight: 1.2,
        bold: false,
        alignment: 'justify',
        preserveLeadingSpaces: true,
    },

    list: {
        margin: [12, 6, 0, 2],
        fontSize: 8,
    },
};

export default PDF_STYLES;
