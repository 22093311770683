const IDEA_GIVER = [
    {
        style: 'h2',
        text: 'Ваш стиль «Генератор идей»',
    },
    {
        style: 'h3',
        text: 'Специализируется на побуждении других к принятию обязательств',
    },
    {
        style: 'body1',
        text:
            'Воспринимает информацию через Конкретный Опыт и обрабатывает ее посредством Обдумывания и Наблюдения.',
    },
    {
        style: 'body1',
        text:
            'Если это ваш стиль, возможно, вам нравятся ситуации, которые требуют разных идей, как, например, во время мозгового штурма. Вы хорошо умеете рассматривать конкретные ситуации с различных точек зрения. Скорее всего у вас обширные культурные интересы и вам нравится собирать информацию.',
    },
    {
        style: 'body1',
        text:
            'Обучающиеся генераторы идей главным образом заинтересованы в поиске смысла. Лидер/руководитель должен доносить до них причину вещей.',
    },
    {
        style: 'list',
        margin: [12, 13, 0, 9],
        ul: [
            [
                { style: 'subtitle', text: 'Сильные стороны' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text:
                        'Развитое воображение, понимание других людей, признание других людей, мозговой штурм.',
                },
            ],
            [
                { style: 'subtitle', text: 'Стиль работы' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Прояснение смысла вкладываемых усилий.',
                },
            ],
            [
                { style: 'subtitle', text: 'Мотивация' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text:
                        'Быть вовлеченным в важные вопросы и создавать гармонию.',
                },
            ],
            [
                { style: 'subtitle', text: 'Любимый вопрос' },
                {
                    style: 'body1',
                    margin: [0, 0, 0, 8],
                    text: 'Почему?',
                },
            ],
        ],
    },
    {
        style: 'body1',
        text:
            'Развитое воображение и способность реагировать на эмоции необходимы для достижения высоких результатов в сферах искусства, организации досуга и развлечений, а также обслуживания.',
    },
    {
        style: 'h4',
        text: 'Люди с данным стилем обучения:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text: 'Интегрируют внешний опыт с собственным;',
            },
            {
                style: 'body1',
                text: 'Учатся, слушая идеи и обмениваясь ими;',
            },
            {
                style: 'body1',
                text:
                    'Являются творческими мыслителями, верящими в собственный опыт;',
            },
            {
                style: 'body1',
                text:
                    'Отличаются способностью рассматривать опыт с различных точек зрения;',
            },
            {
                style: 'body1',
                text: 'Ценят способность доходить до самой сути;',
            },
            {
                style: 'body1',
                text: 'Работают для гармонии, интересуются культурой;',
            },
            {
                style: 'body1',
                text:
                    'Хотят лично участвовать во всем, любят наблюдать за другими;',
            },
            {
                style: 'body1',
                text: 'Ценят в других обязательность;',
            },
            {
                style: 'body1',
                text: 'Во всем ищут смысл и объяснения.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Когда они руководят другими:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text:
                    '«Расцветают», когда есть время на генерирование ценных идей;',
            },
            {
                style: 'body1',
                text:
                    'Руководят так, как подсказывают им сердце, вовлекают других людей в принятие решений;',
            },
            {
                style: 'body1',
                text: 'С подчиненными демонстрируют доверие и участие;',
            },
            {
                style: 'body1',
                text: 'Работают на единство в организации;',
            },
            {
                style: 'body1',
                text:
                    'Знают о социальных факторах, влияющих на развитие человека;',
            },
            {
                style: 'body1',
                text:
                    'Нуждаются/хотят работать с сотрудниками, оказывающими им поддержку и разделяющими их ощущение собственного предназначения.',
            },
        ],
    },
    {
        style: 'h4',
        text: 'Они также:',
    },
    {
        style: 'list',
        ul: [
            {
                style: 'body1',
                text:
                    'Решают задачи, размышляя сначала самостоятельно, а затем в рамках мозгового штурма с другими;',
            },
            {
                style: 'body1',
                text: 'Заинтересованы в содействии личностному развитию;',
            },
            {
                style: 'body1',
                text:
                    'Стараются помогать другим понять свои слабые и сильные стороны;',
            },
            {
                style: 'body1',
                text:
                    'Воспринимают знания как способ найти свое естественное состояние;',
            },
            {
                style: 'body1',
                text: 'Поощряют естественность в других;',
            },
            {
                style: 'body1',
                text:
                    'Любят обсуждения, коллективную работу и честную обратную связь о чувствах;',
            },
            {
                style: 'body1',
                text:
                    'Заботятся о других и стараются вовлекать их в совместную работу;',
            },
            {
                style: 'body1',
                text: 'Способны сконцентрироваться на значимых целях;',
            },
            {
                style: 'body1',
                text:
                    'могут испытывать страх под давлением, также в некоторые моменты им не хватает решительности.',
            },
        ],
    },
];

export default IDEA_GIVER;
