const coordinateAxesData = {
    xPlus: [
        {
            value: 0,
            width: 0,
        },
        {
            value: 7,
            width: 15,
        },
        {
            value: 8,
            width: 25,
        },
        {
            value: 9,
            width: 36,
        },
        {
            value: 10,
            width: 56,
        },
        {
            value: 11,
            width: 67,
        },
        {
            value: 12,
            width: 77,
        },
        {
            value: 13,
            width: 94,
        },
        {
            value: 14,
            width: 107,
        },
        {
            value: 15,
            width: 118,
        },
        {
            value: 16,
            width: 135,
        },
        {
            value: 17,
            width: 145,
        },
        {
            value: 18,
            width: 155,
        },
        {
            value: 19,
            width: 165,
        },
        {
            value: 20,
            width: 172,
        },
        {
            value: 21,
            width: 180,
        },
        {
            value: 22,
            width: 202,
        },
        {
            value: 23,
            width: 221,
        },
        {
            value: 24,
            width: 232,
        },
        {
            value: 25,
            width: 240,
        },
        {
            value: 26,
            width: 249,
        },
        {
            value: 27,
            width: 257,
        },
        {
            value: 28,
            width: 275,
        },
    ],
    xMinus: [
        {
            value: 0,
            width: 0,
        },
        {
            value: 7,
            width: 15,
        },
        {
            value: 8,
            width: 22,
        },
        {
            value: 9,
            width: 30,
        },
        {
            value: 10,
            width: 36,
        },
        {
            value: 11,
            width: 43,
        },
        {
            value: 12,
            width: 51,
        },
        {
            value: 13,
            width: 60,
        },
        {
            value: 14,
            width: 72,
        },
        {
            value: 15,
            width: 78,
        },
        {
            value: 16,
            width: 85,
        },
        {
            value: 17,
            width: 109,
        },
        {
            value: 18,
            width: 130,
        },
        {
            value: 19,
            width: 146,
        },
        {
            value: 20,
            width: 158,
        },
        {
            value: 21,
            width: 170,
        },
        {
            value: 22,
            width: 195,
        },
        {
            value: 23,
            width: 205,
        },
        {
            value: 24,
            width: 213,
        },
        {
            value: 25,
            width: 221,
        },
        {
            value: 26,
            width: 231,
        },
        {
            value: 27,
            width: 240,
        },
        {
            value: 28,
            width: 250,
        },
    ],
    yPlus: [
        {
            value: 0,
            width: 0,
        },
        {
            value: 7,
            width: 5,
        },
        {
            value: 8,
            width: 10,
        },
        {
            value: 9,
            width: 15,
        },
        {
            value: 10,
            width: 30,
        },
        {
            value: 11,
            width: 50,
        },
        {
            value: 12,
            width: 72,
        },
        {
            value: 13,
            width: 85,
        },
        {
            value: 14,
            width: 95,
        },
        {
            value: 15,
            width: 105,
        },
        {
            value: 16,
            width: 112,
        },
        {
            value: 17,
            width: 124,
        },
        {
            value: 18,
            width: 133,
        },
        {
            value: 19,
            width: 141,
        },
        {
            value: 20,
            width: 148,
        },
        {
            value: 21,
            width: 156,
        },
        {
            value: 22,
            width: 171,
        },
        {
            value: 23,
            width: 185,
        },
        {
            value: 24,
            width: 199,
        },
        {
            value: 25,
            width: 212,
        },
        {
            value: 26,
            width: 224,
        },
        {
            value: 27,
            width: 237,
        },
        {
            value: 28,
            width: 250,
        },
    ],
    yMinus: [
        {
            value: 0,
            width: 0,
        },
        {
            value: 7,
            width: 17,
        },
        {
            value: 8,
            width: 20,
        },
        {
            value: 9,
            width: 21,
        },
        {
            value: 10,
            width: 23,
        },
        {
            value: 11,
            width: 25,
        },
        {
            value: 12,
            width: 27,
        },
        {
            value: 13,
            width: 30,
        },
        {
            value: 14,
            width: 34,
        },
        {
            value: 15,
            width: 45,
        },
        {
            value: 16,
            width: 73,
        },
        {
            value: 17,
            width: 86,
        },
        {
            value: 18,
            width: 96,
        },
        {
            value: 19,
            width: 105,
        },
        {
            value: 20,
            width: 115,
        },
        {
            value: 21,
            width: 130,
        },
        {
            value: 22,
            width: 142,
        },
        {
            value: 23,
            width: 157,
        },
        {
            value: 24,
            width: 175,
        },
        {
            value: 25,
            width: 200,
        },
        {
            value: 26,
            width: 216,
        },
        {
            value: 27,
            width: 235,
        },
        {
            value: 28,
            width: 250,
        },
    ],
};

export default coordinateAxesData;
