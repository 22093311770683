import React from 'react';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { ButtonProps } from '@material-ui/core/Button';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import classNames from 'classnames';

import CustomTheme from '../../customTheme';

const useStyles = makeStyles({
    mobileIcon: {
        [CustomTheme.breakpoints.down('xs')]: {
            minWidth: '0',
            width: '40px',
            height: '36px',
            paddingRight: '13px',
            paddingLeft: '13px',
        },
    },

    mobileFullWidth: {
        [CustomTheme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
});

interface IProps extends ButtonProps {
    width: Breakpoint;
    mobileIcon?: boolean;
    mobileFullWidth?: boolean;
    className?: string;
}

const Button: React.FC<IProps> = ({
    width,
    children,
    startIcon,
    endIcon,
    mobileIcon = false,
    mobileFullWidth = false,
    className,
    ...other
}) => {
    const classes = useStyles();
    const size = isWidthUp('lg', width) ? 'large' : 'medium';
    const isIconMode = mobileIcon && isWidthDown('xs', width);

    return (
        <MuiButton
            className={classNames(
                {
                    [classes.mobileIcon]: mobileIcon,
                    [classes.mobileFullWidth]: mobileFullWidth,
                },
                className
            )}
            size={size}
            startIcon={isIconMode ? null : startIcon}
            endIcon={isIconMode ? null : endIcon}
            {...other}
        >
            {isIconMode ? startIcon || endIcon || '' : children}
        </MuiButton>
    );
};

export default withWidth()(Button);
