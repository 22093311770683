import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForward from '@material-ui/icons/ArrowForward';
import useStylesContent from '../../../useStylesContent';

const Tester: React.FC = () => {
    const classes = useStylesContent();

    return (
        <>
            <Typography variant="h2" component="h2" gutterBottom>
                Ваш стиль «Экспериментатор»
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Специализируется на&nbsp;принятии и&nbsp;адаптации изменений
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
                Воспринимает информацию через Конкретный Опыт
                и&nbsp;обрабатывает ее&nbsp;через Активное Экспериментирование.
                Если вы&nbsp;предпочитаете такой стиль, вы, вероятно, любите
                претворять планы в&nbsp;жизнь и&nbsp;решать новые сложные
                задачи, руководствуетесь больше интуицией,
                а&nbsp;не&nbsp;результатами логического анализа. В&nbsp;решении
                задач вы&nbsp;скорее всего больше ориентируетесь на&nbsp;то, как
                действуют другие люди в&nbsp;определенных ситуациях, нежели
                на&nbsp;результаты собственного анализа. Обучающиеся
                экспериментаторы главным образом заинтересованы
                в&nbsp;самопознании. Лидеры/руководители должны
                позволять/помогать им&nbsp;самостоятельно получать знания
                и&nbsp;передавать их&nbsp;другим.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Экспериментаторы:
            </Typography>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Сильные стороны
                        </Typography>
                        <Typography variant="body1">
                            Работа на&nbsp;результат, лидерство, принятие рисков
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Стиль работы
                        </Typography>
                        <Typography variant="body1">
                            Действие и&nbsp;экспериментирование
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">Мотивация</Typography>
                        <Typography variant="body1">
                            Пробовать новые идеи на&nbsp;практике
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ArrowForward />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                        <Typography variant="subtitle1">
                            Любимый вопрос
                        </Typography>
                        <Typography variant="body1">
                            А&nbsp;что, если...?
                        </Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="body1" className={classes.paragraph}>
                Такой стиль обучения важен для достижения успеха
                в&nbsp;профессиях, ориентированных на&nbsp;действия, например,
                в&nbsp;области маркетинга и&nbsp;продаж.
            </Typography>
            <Typography variant="h3" component="h3" gutterBottom>
                Люди с&nbsp;данным стилем обучения:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        интегрируют опыт и&nbsp;практическое применение;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        учатся методом проб и&nbsp;ошибок;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        верят в&nbsp;самопознание;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        с&nbsp;энтузиазмом относятся к&nbsp;новому, легко
                        подстраиваются под изменения и&nbsp;даже получают
                        удовольствие от&nbsp;них;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        преуспевают там, где необходима гибкость;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        часто приходят к&nbsp;точным выводам при отсутствии
                        логического обоснования;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        готовы идти на&nbsp;риск;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        легко общаются с&nbsp;людьми;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        обогащают реальность, дополняя уже известное
                        результатами своих экспериментов;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        ищут пути влияния на&nbsp;других, поэтому иногда
                        производят впечатление манипуляторов и&nbsp;слишком
                        напористых людей.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Когда они руководят другими:
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        &laquo;расцветают&raquo; в&nbsp;ситуациях кризиса
                        и&nbsp;трудных задач;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        в&nbsp;своем лидерстве заряжают людей энергией;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        с&nbsp;подчиненными демонстрируют видение того, каким
                        может быть результат;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        усердно работают, чтобы обеспечить репутацию лидера для
                        своей организации;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        нуждаются/хотят работать с&nbsp;сотрудниками, которые
                        могут осуществлять последующий контроль
                        и&nbsp;заниматься деталями.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" component="h3" gutterBottom>
                Они также
            </Typography>
            <ul className={classes.listNative}>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        решают задачи через поиск характерных проявлений
                        проблем, изучая имеющиеся возможности;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        заинтересованы в&nbsp;том, чтобы обеспечивать людям
                        возможность самопознания;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        помогают людям действовать в&nbsp;соответствии
                        с&nbsp;их&nbsp;собственными представлениями;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        считают знания необходимыми для повышения
                        осведомленности более широкого круга людей;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        поощряют обучение опытным путем;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        любят разнообразие дидактических методов;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        эмоциональные люди, которые любят заряжать других
                        энергией;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        пытаются создать новые формы, оживить все вокруг себя;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        способны устанавливать новые границы;
                    </Typography>
                </li>
                <li className={classes.listNativeItem}>
                    <Typography variant="body1">
                        иногда склонны к&nbsp;поспешным решениям
                        и&nbsp;манипулированию другими.
                    </Typography>
                </li>
            </ul>
        </>
    );
};

export default Tester;
