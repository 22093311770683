import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import type {
    DraggableLocation,
    DraggableProvidedDraggableProps,
    DropResult,
} from 'react-beautiful-dnd';
import {
    DragDropContext,
    Draggable,
    DraggableProvidedDragHandleProps,
    DraggableRubric,
    DraggableStateSnapshot,
    DraggingStyle,
    Droppable,
    DroppableProvidedProps,
    DroppableStateSnapshot,
    NotDraggingStyle,
} from 'react-beautiful-dnd';

import MuiChip from '@material-ui/core/Chip';
import { Done } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { blue, common, grey } from '@material-ui/core/colors';
import CustomTheme from '../../customTheme';

import { IAnswer, IQuestionsLocalData, IZone } from '../../interfaces';

const Chip = withStyles((theme) => ({
    root: {
        '& > .MuiChip-label': {
            [theme.breakpoints.down('xs')]: {
                paddingRight: '10px',
                paddingLeft: '10px',
            },
        },
    },
}))(MuiChip);

const useStyles = makeStyles({
    root: {
        userSelect: 'none',
    },

    options: {
        minHeight: '40px',
        margin: '0 0 20px',
        border: `1px dashed ${grey['300']}`,
        boxShadow: `0px 0px 0px 4px ${common['white']} inset`,
        borderRadius: '20px',
        boxSizing: 'border-box',

        [CustomTheme.breakpoints.up('sm')]: {
            marginBottom: '32px',
        },
    },

    optionsActive: {
        borderColor: blue['A400'],
    },

    optionsFocus: {
        backgroundColor: blue['50'],
    },

    option: {
        display: 'inline',
    },

    optionBlock: {
        border: '3px solid transparent',
        display: 'inline-flex',
        borderRadius: '16px',
    },

    optionDrag: {
        marginTop: '-12px !important',
    },

    optionClone: {
        visibility: 'hidden',
    },

    answerLabel: {
        width: '70px',
        marginBottom: '8px',
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '7px',

        [CustomTheme.breakpoints.up('sm')]: {
            width: '93px',
        },

        [CustomTheme.breakpoints.up('lg')]: {
            width: '112px',
        },
    },

    answerZones: {
        flex: '0 1 100%',
    },

    answerZone: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '100%',
        height: '40px',
        marginBottom: '8px',
        padding: '0 3px',
        border: '1px dashed #E0E0E0',
        borderRadius: '20px',
        alignItems: 'center',
    },

    answerZoneActive: {
        borderColor: blue['A400'],
    },

    answerZoneFocus: {
        backgroundColor: blue['50'],
    },

    answers: {
        display: 'flex',
    },

    answerChip: {
        backgroundColor: common['white'],
    },
});

interface DroppableProvided {
    innerRef: (element: HTMLElement | null) => HTMLDivElement;
    placeholder?: React.ReactElement<HTMLElement> | null;
    droppableProps: DroppableProvidedProps;
}

interface DraggableProvided {
    innerRef: (element?: HTMLElement | null) => HTMLDivElement;
    draggableProps: DraggableProvidedDraggableProps;
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

interface IProps {
    answerOptionData: IAnswer[];
    numberQuestion: number;
    onChange?(isFinish: boolean): void;
    isRestart: boolean;
    handleChangeIsRestart(status: boolean): void;
}

const initialZones = [
    {
        id: '0',
        answer: [],
    },
    {
        id: '1',
        answer: [],
    },
    {
        id: '2',
        answer: [],
    },
    {
        id: '3',
        answer: [],
    },
];

const DragDrop: React.FC<IProps> = ({
    answerOptionData,
    numberQuestion,
    onChange,
    isRestart,
    handleChangeIsRestart,
}) => {
    const classes = useStyles();
    const [isMount, setIsMount] = useState(false);
    const [answerOptions, setAnswerOptions] = useState(answerOptionData);
    const [zones, setZones] = useState<IZone[]>(initialZones);
    const [isFreeZonesHighlight, setIsFreeZonesHighlight] = useState(false);

    useEffect(() => {
        const questionsLocal = getQuestionsLocal();

        setIsMount(true);

        const isAnswersAllSelected = questionsLocal[
            numberQuestion
        ].answers.some((item) => item.answer.length);

        if (isAnswersAllSelected) {
            setAnswerOptions(questionsLocal[numberQuestion].options);
        }

        if (questionsLocal[numberQuestion].answers.length) {
            setZones(questionsLocal[numberQuestion].answers);
        }
    }, [isMount, numberQuestion]);

    useEffect(() => {
        const isFinished = !zones.some((zone) => zone.answer.length < 1);

        if (onChange) {
            onChange(isFinished);
        }
    }, [zones, onChange]);

    useEffect(() => {
        handleChangeIsRestart(false);
    }, [handleChangeIsRestart]);

    useEffect(() => {
        setAnswerOptions(answerOptionData);
        setZones(initialZones);
    }, [answerOptionData, isRestart]);

    const getQuestionsLocal = () => {
        return JSON.parse(
            localStorage.getItem('questions') as string
        ) as IQuestionsLocalData[];
    };

    const getCurrentZoneIndex = (array: IZone[], id: string | undefined) => {
        return array.findIndex((item) => item.id === id);
    };

    const onDragMove = (
        source: IAnswer[],
        destination: IAnswer[],
        droppableSource: DraggableLocation,
        droppableDestination: DraggableLocation
    ) => {
        const sourceClone = [...source];
        const destClone = [...destination];

        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        return {
            [droppableSource.droppableId]: sourceClone,
            [droppableDestination.droppableId]: destClone,
        };
    };

    const getCurrentZone = useCallback(
        (id: string) => zones.filter((zone) => zone.id === id),
        [zones]
    );

    const getIdAnswerList = useCallback(
        (id: string) => {
            if (id === 'droppableOption') {
                return answerOptions;
            } else {
                return getCurrentZone(id)[0].answer;
            }
        },
        [answerOptions, getCurrentZone]
    );

    const getAnswerList = useCallback((id: string) => getIdAnswerList(id), [
        getIdAnswerList,
    ]);

    const onDragStart = useCallback(() => {
        setIsFreeZonesHighlight(true);
    }, []);

    const onDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;

            setIsFreeZonesHighlight(false);

            if (!destination) {
                return;
            }

            if (source.droppableId !== destination.droppableId) {
                const result = onDragMove(
                    getAnswerList(source.droppableId),
                    getAnswerList(destination.droppableId),
                    source,
                    destination
                );

                let currentDroppableId;

                if (source.droppableId !== 'droppableOption') {
                    currentDroppableId = source.droppableId;
                }

                if (destination.droppableId !== 'droppableOption') {
                    currentDroppableId = destination.droppableId;
                }

                const currentZoneIndex = getCurrentZoneIndex(
                    zones,
                    currentDroppableId
                );

                if (
                    source.droppableId !== 'droppableOption' &&
                    destination.droppableId !== 'droppableOption'
                ) {
                    zones[Number(source.droppableId)].answer = [];
                }

                const newZones = zones.map((item) => {
                    return { ...item };
                });
                newZones[currentZoneIndex].answer = result[currentZoneIndex];

                const questionsLocal = getQuestionsLocal();

                if (
                    source.droppableId === 'droppableOption' ||
                    destination.droppableId === 'droppableOption'
                ) {
                    questionsLocal[numberQuestion].options =
                        result.droppableOption;

                    setAnswerOptions(result.droppableOption);
                }

                questionsLocal[numberQuestion].answers = newZones;

                localStorage.setItem(
                    'questions',
                    JSON.stringify(questionsLocal)
                );

                setZones(newZones);
            }
        },
        [getAnswerList, numberQuestion, zones]
    );

    const isDraggingOver = (snapshot: DroppableStateSnapshot) => {
        return !!(snapshot.isDraggingOver || snapshot.draggingFromThisWith);
    };

    const isFreeZoneHighlight = (
        snapshot: DroppableStateSnapshot,
        isFreeZoneHighlight: boolean
    ) => {
        return isFreeZonesHighlight && isFreeZoneHighlight;
    };

    const getAnimationStyle = (
        style: DraggingStyle | NotDraggingStyle | undefined,
        snapshot: DraggableStateSnapshot
    ) => {
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`,
        };
    };

    const getRenderDraggableOptions = (items: IAnswer[]) =>
        function renderDraggableOptions(
            provided: DraggableProvided,
            snapshot: DraggableStateSnapshot,
            rubric: DraggableRubric
        ) {
            return (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames({
                        [classes.option]: true,
                        [classes.optionDrag]: snapshot.isDragging,
                    })}
                    style={getAnimationStyle(
                        provided.draggableProps.style,
                        snapshot
                    )}
                >
                    <div className={classes.optionBlock}>
                        <Chip
                            clickable
                            label={items[rubric.source.index].content}
                        />
                    </div>
                </div>
            );
        };

    return (
        <div className={classes.root}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="droppableOption" direction="horizontal">
                    {(provided: DroppableProvided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classNames({
                                    [classes.options]: true,
                                    [classes.optionsActive]: isFreeZoneHighlight(
                                        snapshot,
                                        true
                                    ),
                                    [classes.optionsFocus]: isDraggingOver(
                                        snapshot
                                    ),
                                })}
                            >
                                {answerOptions.map(({ id, content }, index) => {
                                    return (
                                        <React.Fragment key={id}>
                                            <Draggable
                                                draggableId={id}
                                                index={index}
                                            >
                                                {getRenderDraggableOptions(
                                                    answerOptions
                                                )}
                                            </Draggable>
                                            {id ===
                                            snapshot.draggingFromThisWith ? (
                                                <div
                                                    className={classNames({
                                                        [classes.option]: true,
                                                        [classes.optionClone]: true,
                                                    })}
                                                >
                                                    <div
                                                        className={
                                                            classes.optionBlock
                                                        }
                                                    >
                                                        <Chip
                                                            clickable
                                                            label={content}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>

                <div className={classes.answers}>
                    <div>
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.answerLabel}
                        >
                            Подходит
                        </Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.answerLabel}
                        >
                            Менее подходит
                        </Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.answerLabel}
                        >
                            Еще меньше подходит
                        </Typography>
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.answerLabel}
                        >
                            Меньше всего подходит
                        </Typography>
                    </div>

                    <div className={classes.answerZones}>
                        {zones.map(({ id, answer }) => (
                            <Droppable
                                droppableId={id}
                                key={id}
                                isDropDisabled={answer.length >= 1}
                            >
                                {(provided: DroppableProvided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={classNames({
                                            [classes.answerZone]: true,
                                            [classes.answerZoneActive]: isFreeZoneHighlight(
                                                snapshot,
                                                answer.length < 1
                                            ),
                                            [classes.answerZoneFocus]: isDraggingOver(
                                                snapshot
                                            ),
                                        })}
                                    >
                                        {answer.map(
                                            ({ id, content }, index) => (
                                                <Draggable
                                                    key={id}
                                                    draggableId={id}
                                                    index={index}
                                                >
                                                    {(
                                                        providedDraggable: DraggableProvided,
                                                        snapshotDraggable
                                                    ) => (
                                                        <div
                                                            ref={
                                                                providedDraggable.innerRef
                                                            }
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}
                                                            style={getAnimationStyle(
                                                                providedDraggable
                                                                    .draggableProps
                                                                    .style,
                                                                snapshotDraggable
                                                            )}
                                                        >
                                                            <Chip
                                                                clickable
                                                                color="primary"
                                                                variant="outlined"
                                                                label={content}
                                                                deleteIcon={
                                                                    <Done />
                                                                }
                                                                className={
                                                                    classes.answerChip
                                                                }
                                                                onDelete={() =>
                                                                    console.log()
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </div>
            </DragDropContext>
        </div>
    );
};

export default DragDrop;
