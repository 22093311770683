import React from 'react';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import { grey } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import customTheme from '../../customTheme';

import Button from '../Button';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        padding: '24px 20px 12px',
        backgroundColor: grey['50'],
        marginLeft: '-20px',
        marginRight: '-20px',
        marginBottom: '32px',

        [customTheme.breakpoints.up('sm')]: {
            marginLeft: '-48px',
            marginRight: '-48px',
            padding: '24px 48px 48px',
            marginBottom: '60px',
        },

        [customTheme.breakpoints.up('md')]: {
            marginLeft: '-92px',
            marginRight: '-92px',
            paddingLeft: '92px',
            paddingRight: '92px',
        },

        [customTheme.breakpoints.up('lg')]: {
            marginLeft: '-136px',
            marginRight: '-136px',
            padding: '48px 136px 68px',
            marginBottom: '104px',
        },
    },

    info: {
        marginBottom: '12px',

        [customTheme.breakpoints.up('lg')]: {
            marginBottom: '20px',
        },
    },

    controls: {
        [customTheme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },

    buttons: {
        '& button': {
            marginBottom: '12px',

            [customTheme.breakpoints.up('sm')]: {
                marginBottom: 0,
                marginLeft: '20px',
            },

            '&:last-child': {
                marginBottom: 0,
            },
        },
    },

    buttonRollUp: {
        [customTheme.breakpoints.up('sm')]: {
            position: 'absolute',
            right: '48px',
            top: '24px',
        },

        [customTheme.breakpoints.up('md')]: {
            right: '92px',
        },

        [customTheme.breakpoints.up('lg')]: {
            right: '136px',
            top: '48px',
        },
    },
});

const CustomTextField = withStyles((theme) => ({
    root: {
        marginBottom: '12px',

        [customTheme.breakpoints.up('sm')]: {
            flex: '0 0 66.270431%',
            marginBottom: 0,
        },

        '& .MuiFormLabel-root': {
            fontSize: 'inherit',
            lineHeight: '1',

            [customTheme.breakpoints.up('lg')]: {
                fontSize: '16px',
                lineHeight: '20px',
            },
        },

        '& .MuiInputBase-root': {
            fontSize: 'inherit',

            [customTheme.breakpoints.up('lg')]: {
                fontSize: '16px',
                lineHeight: '24px',
            },
        },

        '& .MuiInputBase-input': {
            [customTheme.breakpoints.up('lg')]: {
                height: 'auto',
            },
        },

        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
}))(TextField);

interface IProps {
    value: string;
    indexForm: number;
    onHide?(index: number): void;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onDownload?(event: React.MouseEvent<HTMLButtonElement>): void;
}

const Form: React.FC<IProps> = ({
    value,
    indexForm,
    onHide,
    onChange,
    onDownload,
}) => {
    const classes = useStyles();

    const handleDownloadClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (onDownload) {
            onDownload(event);
        }
    };

    const handleHideClick = () => {
        if (onHide) {
            onHide(indexForm);
        }
    };

    return (
        <div className={classes.root}>
            <Typography variant="subtitle1" gutterBottom>
                Введите имя и&nbsp;фамилию
            </Typography>

            <Typography variant="body1" className={classes.info}>
                Данные будут добавлены в&nbsp;файл с&nbsp;отчётом, чтобы
                он&nbsp;стал персональным. Мы&nbsp;не&nbsp;храним
                и&nbsp;не&nbsp;передаём никому ваши данные о&nbsp;прохождении
                теста. Если не&nbsp;хотите добавлять, оставьте поле пустым.
            </Typography>

            <div className={classes.controls}>
                <CustomTextField
                    label="Введите имя и фамилию"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={onChange}
                    value={value}
                />

                <div className={classes.buttons}>
                    <Button
                        variant="contained"
                        color="primary"
                        mobileFullWidth
                        startIcon={<VerticalAlignBottom />}
                        onClick={handleDownloadClick}
                    >
                        скачать файл в pdf
                    </Button>

                    <Button
                        className={classes.buttonRollUp}
                        color="primary"
                        mobileFullWidth
                        onClick={handleHideClick}
                    >
                        свернуть
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Form;
