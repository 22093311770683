import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import customTheme from '../../customTheme';
import MainContainer from '../MainContainer';

import Start from '../Start';
import Questions from '../Questions';
import Result from '../Result';
import Footer from '../Footer';

import PAGES from '../../constants';
import initialQuestionsLocalData from '../../data/questionsLocalData';
import { IQuestionsLocalData } from '../../interfaces';

function App() {
    const stepFromLocalStorage = localStorage.getItem('step');
    const questionsLocal = localStorage.getItem('questions') as string;
    const currentQuestionsLocale = questionsLocal
        ? questionsLocal
        : JSON.stringify([initialQuestionsLocalData]);
    const currentStep = stepFromLocalStorage ? Number(stepFromLocalStorage) : 0;
    const [step, setStep] = useState(currentStep);
    const [questionsLocalArray, setQuestionsLocalArray] = useState<
        IQuestionsLocalData[]
    >([initialQuestionsLocalData]);

    useEffect(() => {
        localStorage.setItem('questions', currentQuestionsLocale);
        setQuestionsLocalArray(JSON.parse(currentQuestionsLocale));
    }, [currentQuestionsLocale]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const renderStep = () => {
        if (step >= PAGES.startQuestionsIndex && step < PAGES.result) {
            return <Questions handleSetStep={handleSetStep} step={step} />;
        }

        if (step === PAGES.result) {
            return <Result handleSetStep={handleSetStep} />;
        }

        return (
            <Start
                handleSetStep={handleSetStep}
                setStep={setStep}
                questionsLocalArray={questionsLocalArray}
            />
        );
    };

    const handleSetStep = (direction: string) => {
        if (direction === 'next') {
            setStep((prevStep) => {
                localStorage.setItem('step', (step + 1).toString());
                return prevStep + 1;
            });
        }

        if (direction === 'prev') {
            setStep((prevStep) => {
                localStorage.setItem('step', (step - 1).toString());
                return prevStep - 1;
            });
        }

        if (direction === 'restart') {
            setStep(PAGES.startPagesIndex + 1);
            localStorage.setItem(
                'step',
                (PAGES.startPagesIndex + 1).toString()
            );
            localStorage.setItem(
                'questions',
                JSON.stringify([initialQuestionsLocalData])
            );
        }

        if (direction === 'startPage') {
            setStep(PAGES.startPagesIndex);
            localStorage.setItem('step', PAGES.startPagesIndex.toString());
        }
    };

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <MainContainer maxWidth={false} disableGutters={true}>
                {renderStep()}
                {process.env.REACT_APP_VERSION === 'smarthead' && <Footer />}
            </MainContainer>
        </ThemeProvider>
    );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
